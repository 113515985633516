import React, { useState, useEffect } from 'react';
import cx from 'classnames'
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const dots = [2,3,4]

const FormDots = ({step}) => {

    const classes = useStyles()

    return ( 
        <div className={classes.root}>
            <Grid container justifyContent='center' spacing={2}>
                {dots.map(item => {
                    return(
                        <Grid item key={item.toString()}>
                            <div className={cx({
                                [classes.dot]:true,
                                [classes.dot_selected]:step === item
                            })}/>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        //marginLeft:24,
        marginBottom:24
    },
    dot:{
        width:10,
        height:10,
        borderRadius:'50%',
        background:grey[400]
    },
    dot_selected:{
        background:theme.palette.primary.main
    }
}))
 
export default FormDots;