import React from 'react'
import { CircularProgress, Fab, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, Switch } from '@material-ui/core'
import { onGetFullname } from '../../../shared/utility'
import SearchBar from '../../../components/Actions/SearchBar/SearchBar'
import ActionBar from '../../../components/Actions/ActionBar'
import empty from '../../../assets/icons/empty.png'
import GenericStatus from '../../../components/Structure/DisplayData/GenericStatus'
import tableStyles from '../../../styles/tableStyles'
import UserAvatarName from '../../../components/Structure/DisplayData/UserAvatarName'
import { Rating } from '@material-ui/lab';




const SimpleTable = props => {

    const classes = tableStyles()
    const {data, task_type, filter, content, onChangeFilter, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, year, onRemoveItem, onChangeSearch, 
        onAddRegister, onDeleteItem, onChangeApproved} = props
    const header = content.header
    //console.log(data)

    const onSwitchApproved = (event, id) => {
        console.log(event.target.checked)
        let appr = event.target.checked ? 1 : 0
        onChangeApproved(id, appr)
    }

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container spacing={2} alignItems='center' > 
                    <Grid item xs/>
                    <Grid item><SearchBar value={filter.query} onChange={onChangeSearch} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/></Grid>         
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <Typography variant='subtitle2' className={classes.header} >{item.label}</Typography>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item, index) => {

                                    const {id_review, user_first_name, user_last_name, user_image, comment, general_satisfaction, approved } = item

                                    const name = onGetFullname(user_first_name, user_last_name)

                                    return(
                                        <TableRow key={index.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body1'>{id_review}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><UserAvatarName name={name} image={user_image} nopicture={content.nouser}/></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body1'>{comment}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Rating readOnly value={general_satisfaction} precision={0.5} /></TableCell>
                                            <TableCell className={classes.cell} ><Switch checked={Boolean(approved)} onChange={(e)=>onSwitchApproved(e, id_review)} inputProps={{ 'aria-label': 'controlled' }} color='primary'  /></TableCell>
                                            {/* <TableCell className={classes.cell}  padding='checkbox'>
                                                <Grid container spacing={1} wrap='nowrap'>
                                                    <Grid item>
                                                        <IconButton size='small' onClick={() => onDeleteItem(item)} ><Icon>delete</Icon></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </TableCell> */}
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <div className={classes.empty_container}>
                                <img src={empty} alt='' className={classes.empty}/>
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography>No se encontraron registros</Typography>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable