import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import AdministratorsView from '../views/Administrators/AdministratorsView/AdministratorsView'
import AgenciesView from '../views/Agency/Agencies/AgenciesView'
import BrandsView from '../views/Brands/Brands/BrandsView'
import CategoriesView from '../views/Categories/CategoriesView'
import InfluencersView from '../views/Influencers/InfluencersView/InfluencersView'
import ManagersView from '../views/Managers/ManagersView/ManagersView'
import ProfileView from '../views/Profile/ProfileView/ProfileView'
import Reviews from '../views/Reviews/Reviews'
import UserDetailsView from '../views/Users/UserDetailsView/UserDetailsView'
import UsersView from '../views/Users/UsersView/UsersView'
import ResumeView from '../views/Resume/ResumeView'
import DeletedView from '../views/Deleted/DeletedView'

const DashboardRouter = props => {
    return(
        <Switch>
            <Route exact path='/' component={AdministratorsView} />
            <Route exact path='/reviews' component={Reviews} />
            <Route exact path='/categories' component={CategoriesView} />
            <Route exact path='/users/:id' component={UserDetailsView} />
            <Route exact path='/users' component={UsersView} />
            <Route exact path='/resume' component = {ResumeView} />
            <Route exact path='/influencers' component={InfluencersView} />
            <Route exact path='/managers' component={ManagersView} />
            <Route exact path='/deleted' component={DeletedView} />
            <Route exact path='/brands' component={BrandsView} />
            <Route exact path='/agencies' component={AgenciesView} />
            <Route exact path='/profile' component={ProfileView} />
            <Redirect to='/users' />
        </Switch>
    )
}

export default DashboardRouter

