import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
//import { general_translations as translations_esp } from '../../../texts/esp/general-translations';

const Footer = ({content}) => {

    const classes = useStyles()
    //const content = translations_esp.footer

    return ( 
        <div className={classes.root}>
            <Grid container justifyContent='center' spacing={8}>
                <Grid item md xs={12}>
                    <Grid container spacing={2} justifyContent='center' >
                        <Grid item xs={12}>
                            <Typography align='center' variant='h5'>{content.social_media.title}</Typography>
                        </Grid>
                        {content.social_media.media.map(item =>{
                            return(
                            <Grid item key={item.id.toString()}>
                                <img src={item.icon} style={{width:40}} />
                            </Grid>
                        )})}
                    </Grid>
                </Grid>
                <Grid item>
                    <div className={classes.line}></div>
                </Grid>
                <Grid item>
                    <Grid container direction='column' spacing={3}>
                        {content.links.map(item => {
                            return(
                            <Grid item key={item.id.toString()}>
                                <a href={item.link} className={classes.link} >
                                    {item.label}
                                </a>
                            </Grid>
                        )})}
                    </Grid>
                </Grid>               
                <Grid item>
                    <Grid container direction='column' spacing={3}>
                        {content.contact_links.map(item => {
                            return(
                            <Grid item key={item.id.toString()}>
                                <a href={item.link} className={classes.link} >
                                    {item.label}
                                </a>
                            </Grid>
                        )})}
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction='column' spacing={3}>
                        {content.legal_links.map(item => {
                            return(
                            <Grid item key={item.id.toString()}>
                                <a target={item.target} href={item.url} className={classes.link} >
                                    {item.label}
                                </a>
                            </Grid>
                        )})}
                    </Grid>
                </Grid>
            </Grid>
            <Typography align='center' style={{fontSize:12, marginTop:80}}>Copyright © 2021 InPager</Typography>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        color:'white',
        padding:'60px 40px 20px',
        minHeight:200,
        background:'black',
    },
    links:{
        display:'flex',
        color:'white',
        fontFamily:theme.typography.fontFamily,
        color:'white',
        textDecoration:'none',
        [theme.breakpoints.down('sm')]:{
            justifyContent:'center'
        }
    },
    link:{
        //marginRight:8,
        //fontWeight:500,
        fontFamily:theme.typography.fontFamily,
        color:'white',
        textDecoration:'none'
    },
    media_icon:{
        width:40
    },
    legal_container:{
        marginTop:16
    },
    line:{
        width:1,
        height:'100%',
        background:'#E5E5E5',
        [theme.breakpoints.down('sm')]:{
            display:'none'
        }
    }
}))
 
export default Footer;