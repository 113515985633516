import nouser from '../../assets/nouser.png'

export const translations = {
    title:'Influencers',
    table:{
        header:[
            {id:0, label:'*'},
            {id:1, label:'Nombre'},
            {id:2, label:'ID'},
            {id:3, label:'Usuario'},
            {id:4, label:'Correo electrónico'},
            {id:5,label:'Fecha de registro'},
            {id:6,label:'Fecha de verificación'}, 
            {id:7, label:'Membresía'},
            {id:8,label:'Ciudad / País'},
            {id:9,label:'Género'},
            {id:10,label:'Edad'},
            {id:11,label:'Visitas'},
            {id:12,label:'Manager'},
            {id:13,label:'Tipos de figura pública'},
            {id:14,label:'Tipo de contenidos'},
            {id:15, label:'Eliminar'},
        ],
        nodata:'No existen usuarios registrados',
        nouser:nouser
    }
    
}

export default translations