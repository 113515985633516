import { Button, Grid, Icon, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect } from 'react';

const RegisterMethodSection = ({content, history, actions}) => {

    const classes = useStyles();

    const {REGISTER_STEPS} = content
    const _content = content.register_method_section

    return ( 
        <div>
            <div className={classes.content_data}>
                <Typography variant='h4'>
                    {_content.title}
                </Typography>
                

                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Button fullWidth className={classes.button} onClick={() => actions.onChangeForwardStep(REGISTER_STEPS.USER_TYPE_SELECTION)}>
                                Correo electrónico
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <div style={{position:'relative'}}>
                                <div style={{
                                    position:'absolute', top:14,
                                    borderTop:`1px solid grey`,
                                    width:'100%',
                                    zIndex:-1
                                }}/>
                                <Grid container justifyContent='center'>
                                    <Grid item>
                                    <Typography align='center' style={{background:'white'}}>o</Typography>

                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item  xs={12}>
                            <Button fullWidth className={classes.social_button}>
                                <img src={_content.google_logo} width={24} style={{marginRight:8}}/>
                                Google
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Button fullWidth className={classes.social_button}>
                                <img src={_content.facebook_logo} width={24} style={{marginRight:8}}/>
                                Facebook
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color='textSecondary' align='center'>
                                Al continuar con tu registro, aceptas nuestras
                            </Typography>
                            <Typography variant='subtitle2' align='center'>
                                Condiciones de uso, Política de datos y Política de cookies
                            </Typography>
                        </Grid>
                    </Grid>
                    

                    
                </div>
                
                
                <div style={{marginTop:120, paddingBottom:100}}>
                    <Typography align='center'>{_content.noaccount}</Typography>
                    <div className={classes.link} onClick={() => history.push('/signin')}
                    style={{marginTop:8, textAlign:'center', textDecoration:'none'}}>
                        {_content.joinus}
                    </div>
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    content_data:{
        marginTop:140,
    },
    link:{
        color:theme.palette.primary.main,
        fontWeight:600,
        textDecoration:`underline ${theme.palette.primary.main}`,
        cursor:'pointer'

    },
    button:{
        fontSize:18,
        textTransform:'none',
        background:theme.palette.primary.main,
        color:'white',
        fontWeight:600,
        padding:12,
        borderRadius:40,
        '&:hover':{
            background:theme.palette.primary.main,
        }
    },
    social_button:{
        fontSize:18,
        textTransform:'none',
        border:`1px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main,
        fontWeight:600,
        padding:12,
        borderRadius:40
    },
    form:{
        
        margin:'60px auto',
        width:400
    }
}))
 
export default RegisterMethodSection;