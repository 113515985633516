import React from 'react';
import { Drawer, alpha, Grid, Icon, makeStyles, Typography, useMediaQuery, useTheme, SvgIcon } from '@material-ui/core';
import { connect } from 'react-redux';
import { actionTypes } from '../../../store/actions';
import cx from 'classnames';
import { grey, pink } from '@material-ui/core/colors';
import { withRouter } from 'react-router';
import system_translations from '../../../texts/system_translations';
import { CONFIG_SETTINGS, DASHBOARD_SETTINGS } from '../../../config';
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon';

const Sidebar = ({drawer_status, history, ismobile, language, user, onUpdateDrawerStatus}) => {

    
    const classes = useStyles()

    const isURLSelected = (path) => {
        return history.location.pathname.split('/')[1] === path.split('/')[1];
    }

    const onChangeView = (url) => {
        history.push(url)
    }

    const content = system_translations[language].general.sidebar

    let user_level = 1;

    if(user) user_level = user.user_type_id ? user.user_type_id : 100 
    console.log(user_level)

    return ( 
        <div>
            <Drawer open={drawer_status} 
                variant={ismobile ? 'temporary' : 'persistent'}
                //variant='temporary'
                classes={{paper:classes.drawer}}
                onClose={() => onUpdateDrawerStatus(false)}
            >
                <div className={classes.container}>
                    <div className={classes.logowrapper}>
                        <div className={classes.logo_container}>
                            <img src={content.logo} alt='' className={classes.logo}/>
                        </div>
                    </div>
                    
                    <div className={classes.menu}>
                        <Grid container spacing={3}>
                            {content.list.map((item,key)=>{

                                
                                const isactive = isURLSelected(item.path)

                                return(
                                    <Grid item xs={12} key={key.toString()}>
                                        <SidebarItem active={isactive} {...item} onChangeView={onChangeView}/>
                                    </Grid>
                                )
                            })} 
                        </Grid>
                        
                    </div>
                </div>
                
            </Drawer>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    drawer:{
        background:'white',
        width:DASHBOARD_SETTINGS.PROFILE_DRAWER.WIDTH,
        borderTopRightRadius:24,
        borderBottomRightRadius:24,
        border:0,
        boxShadow:'2px 4px 15px rgba(0, 0, 0, 0.14)',
    },
    logo:{
        width:'100%',
        //height:'100%',
        padding:8,
        boxSizing:'border-box',
        //objectFit:'cover',
        position:'absolute',
        top:0, left:0
    },
    logowrapper:{
        background:'white'
    },
    logo_container:{
        marginTop:32,
        width:180,
        padding:'70px 0px',
        paddingBottom:16,
        //height:100,
        margin:'auto',
        //borderRadius:'50%',
        boxSizing:'border-box',
        background:'white',
        position:'relative'
    }, 
    container:{
        //width:150,
        padding:'0px 0px'
    },
    title:{
        color:'white',
        marginTop:8
    },
    menu:{
        margin:'32px 0px'
    }
}))

const mapStateToProps = state => {
    return {
        drawer_status:state.drawer_status
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateDrawerStatus: (drawer_status) => dispatch({type:actionTypes.SYS_UPDATE_DRAWER_STATUS, drawer_status})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));


const SidebarItem = ({active, name, path, image,  svg_path, onChangeView}) => {

    const classes = useStylesItem()

    return(
        
            <div className={cx(classes.root,
            {[classes.active]:active
            })} onClick={() => onChangeView(path)}>
                {active ? <div className={classes.decoration}/> : null}
                <Grid container  spacing={1} alignItems='center'>
                    <Grid item>
                        <SVGIcon src={image} size={28} 
                        color={active ? '#843BA8' : '#696969'} 
                        />
                    </Grid>
                    <Grid item >
                        <Typography align='center' variant='body2' style={{fontSize:14, fontWeight: active ? 600 : 500}}>{name}</Typography>
                    </Grid>
                </Grid>
            </div>
       
    )
}

const useStylesItem = makeStyles(theme => ({
    root:{
        //color:grey[300],
        color:'#696969',
        padding:'8px 28px',
        //marginTop:8, 
        //background:'red',
        position:'relative',
        '&:hover':{
            background:alpha("#FFF",0.25),
            cursor:'pointer',
            transition: theme.transitions.create(['background'], {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
        
    },
    active:{
        background:'rgba(132, 59, 168, 0.04)',
        color:theme.palette.primary.main,
        '&:hover':{
            background:alpha("#FFF",0.25),
            cursor:'pointer'
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    decoration:{
        position:'absolute',
        top:0, right:0,
        width:6,
        height:'100%',
        background:theme.palette.primary.main,
        borderRadius:16
    }
}))