export const ERROR_CODES = {
    "101":"La solicitud no tiene encabezado de autorización",
    "102":"El token ha expirado",
    "103":"Token inválido",
    "104":"Credenciales inválidas",
    "105":"El usuario no tiene autorización para realizar esta acción",
    "106":"Contraseña incorrecta",
    "107":"Error de autorización",
    "108":"El usuario se encuentra deshabilitado",
    "109":"La reseña del token es requerida",
    "201":"La petición contiene datos inválidos",
    "301":"Error in el servidor o la base de datos",
    "302":"Error al descifrar la contraseña",
    "303":"Error al cifrar la contraseña",
    "304":"Error al subir la imagen",
    "305":"Error al subir el archivo",
    "306":"Error al listar los archivos",
    "307":"Error al eliminar el archivo",
    "308":"Error al descargar el archivo",
    "309":"Archivo faltante",
    "310":"Error en los servicios de mensajería",
    "311":"Archivo no encontrado",
    "312":"Error en el servicio de facturación",
    "313":"Error en el servicio de facebook",
    "401":"Usuario no encontrado",
    "402":"Esa dirección de correo electrónico ya está en uso",
    "403":"Ese nombre de usuario ya está en uso",
    "404":"Usuario no público",
    "405":"Tipo de usuario no encontrado",
    "406":"Esa url ya está en uso",
    "407":"Esa dirección de correo electrónico ya está en uso",
    "408":"Género no encontrado",
    "409":"País no encontrado",
    "410":"Tipo de influencer no encontrado",
    "411":"Talla de influencer no encontrada",
    "412":"Cliente del influencer no encontrado",
    "413":"Archivo no encontrado",
    "414":"Influencer no encontrado",
    "415":"Cliente no encontrado",
    "416":"El influencer no pertenece al cliente",
    "417":"Ese usuario no es influencer",
    "418":"Categoría no encontrada",
    "419":"Esa categoría ya existe",
    "420":"Campo no encontrado",
    "421":"No se ha encontrado esa categoría de influencer",
    "422":"Esa categoría de influencer ya existe",
    "423":"La categoría de influencer no pertenece al usuario",
    "424":"Reseña no encontrada",
    "425":"La reseña no pertenece al usuario",
    "426":"Evento no encontrado",
    "427":"Tipo de evento no encontrado",
    "428":"Modo de evento no encontrado",
    "429":"Tag no encontrada",
    "430":"Tag de evento no encontrada",
    "431":"Empresa no encontrada",
    "432":"La empresa no pertenece al usuario",
    "433":"El evento no pertenece al usuario",
    "434":"La tag del evento no pertenece al usuario",
    "435":"Producto no encontrado",
    "436":"El producto no pertenece al usuario",
    "437":"El código de referencia ya existe",
    "438":"Referencia no encontrada",
    "439":"El usuario no ha sido confirmado",
    "440":"Usuario deshabilitado",
    "441":"Reseña no aprovada",
    "442":"Imagen de usuario no encontrada",
    "443":"La imagen no pertenece al usuario",
    "444":"Analítico de facebook no encontrado ",
    "445":"Analítico de instagram no encontrado",
    "446":"Integración no encontrada",
    "447":"Faltan los permisos del token de facebook",
    "448":"Token de facebook inválido",
    "449":"Invitación no encontrada",
    "450":"La invitación no pertecene al usuario",
    "451":"Estatus de la invitación no encontrado",
    "452":"La invitación debería estar en estatus de enviado",
    "453":"El usuario no es cliente de la invitación",
    "454":"El usuario no es influencer de la invitación",
    "455":"El usuario no tiene cliente",
    "456":"El usuario no tiene reseñas",
    "457":"El usuario no se ha conectado a una página de Facebook",
    "458":"El usuario no se ha conectado a una página de Instagram",
    "459":"Payment repeated in last minutes",
    "460":"Not Confimed Email already used",
    "461":"Not Confimed Mobile already used",
    "462":"Email already confirmed",
    "463":"Email already in use",
    "464":"Mobile already in use",
    "465":"Invalid code",
    "466":"Mobile already confirmed",
    "467":"Email not confirmed yet",
    "468":"Mobile not confirmed yet",
    "469":"Error downloading file",
    "470":"Chat media not found",
    "471":"Error uploading file",
    "472":"Chat media does not belong to provided user",
    "473":"Error deleting files",
    "474":"Internal Error Server: currently, our server is down, please try this action in a few more minutes.",
    "475":"Patient document not found",
    "476":"Patient document does not belong to provided user",
    "477":"Patient image not found",
    "478":"Patient image does not belong to provided user",
    "479":"Email or mobile are missing.",
    "480":"Language not found in translations",
    "481":"Doctors patient not found",
    "482":"Doctor does not belong",
    "483":"Patient does not belong",
    "484":"Vital sign not found",
    "485":"Vital sign does not belog",
    "486":"Anthropometric data not found",
    "487":"Anthropometric does not belong",
    "488":"Physycal exploration data not found",
    "489":"Physycal exploration data does not belong",
    "490":"General diagnosis not found",
    "491":"System interrogation data not found",
    "492":"System interrogation data does not belong",
    "493":"To finish an appointment you must add, at least, one diagnosis. ",
    "494":"Appointment already finished",
    "495":"There must be a recipe to finish the appointment",
    "496":"Speciality not found","497":"Payment type not found",
    "498":"Doctor availability not found",
    "499":" Doctor Availability is reserved",
    "500":"Study request not found",
    "501":"Study request does not belong to the provider user",
    "502":"Study type not found",
    "503":"Source id is needeed to perfom this action",
    "504":"Order hasn't been aproved yet",
    "505":"Order id doesn't belong to provider payment",
    "506":"Payer has not yet approved the Order for payment.",
    "507":"Payer has not yet approved the Order for payment. ",
    "508":"This order has been previously voided and cannot be voided again.",
    "509":"Duplicate transaction detected.",
    "510":"The requested action could not be performed,  failed business validation. ",
    "511":"The amount specified does not match the breakdown. ",
    "512":"The authorization and capture feature is not enabled for the merchant. Make sure that the recipient of the funds is a verified business account.",
    "513":"The account validations failed for the user.",
    "514":"The specified authorization amount exceeded the allowable limit.",
    "515":"The requested Billing Agreement token was not found. ",
    "516":"Payment value must be greater than zero.",
    "517":"Processing of this card type is not supported. Use another card type.",
    "518":"The security_code length is invalid for the specified card type.",
    "519":"The specified country requires a city, specify a city and try the request again.",
    "520":"Transaction cannot be processed due to a possible compliance violation. ",
    "521":"To continue with this transaction, the payer must provide consent.",
    "522":"The currency code is not supported for direct card payments for this card type. ",
    "523":"Currency code not supported for direct card payments in this country. ",
    "524":"The value of the field should not be more than two decimal places. ",
    "525":"This transaction requires the payee and payer to be resident in the same country.",
    "526":"The funding instrument presented was either declined by the processor or bank. ",
    "527":"An internal server error has occurred. ","528":"An internal service error has occurred. ",
    "529":"To continue with this transaction, the payer must provide consent.",
    "530":"Country code is invalid. ","531":"Currency code is invalid or is not currently supported. ",
    "532":"The payer ID is not valid. ",
    "533":"Specified resource ID does not exist. ",
    "534":"You have exceeded the maximum number of payment attempts. ",
    "535":"Should be less than or equal to 9999999.99.  Try the request again with a different value.",
    "536":"To check that your application has sufficient permissions, log in to the PayPal Developer Portal.",
    "537":"The order was already authorized and you can create only one authorization for an order.",
    "538":"The order was already captured and you can capture only one payment for an order.",
    "539":"The order cannot be patched after it is completed. ","540":"Order is voided or completed and hence cannot be authorized.",
    "541":"Order is expired and hence cannot be authorized. ",
    "542":"Please save the order",
    "543":"Payee account specified is invalid.",
    "544":"Payee account is locked or closed. ",
    "545":"The merchant account is restricted.",
    "546":"The fraud settings for this seller are such that this payment cannot be executed. ",
    "547":"Payer account is locked or closed. ",
    "548":"Payer account is restricted. ",
    "549":"Payer cannot pay for this transaction. ",
    "550":"The payer has not provided appropriate consent to proceed with this transaction.",
    "551":"Payer Country is not supported.",
    "552":"The specified country requires a postal code. ",
    "553":"The transaction failed. Redirect the payer to select another funding source.",
    "554":"The transaction was blocked by the payee’s Fraud Protection settings.",
    "555":"The transaction was refused. ",
    "556":"Payee does not have an account with PayPal. Your current setup requires the 'payee' to have a verified account with PayPal before you can process transactions on their behalf.",
    "557":"Payee has not verified their account with PayPal. Your current setup requires the 'payee' to have an account with PayPal before you can process transactions on their behalf.",
    "558":"Payee does not have appropriate consent to allow the API caller to process this type of transaction on their behalf. Your current setup requires the 'payee' to provide a consent before this transaction can be processed successfully.",
    "559":"Paypal error unknown",
    "560":"Availability no longer available",
    "561":"Availability hasn't been reserved by the provider user",
    "562":"Provided payment has already been applied",
    "563":"It's not possible to schedule in this date.",
    "564":"Availability already released",
    "565":"It's not possible to cancel in less than 24 hours.",
    "566":"Appointment has already been cancelled",
    "567":"Speciality not found",
    "568":"Payment concept not found",
    "569":"Not valid users payment custom",
    "570":"Provided payment method doesn't belong to provided user",
    "571":"This Payment Method was previously attached to a Customer or was detached from a Customer",
    "572":"This Payment Method must be saved to a customer before it can be updated.",
    "573":"Provided payment method does not belong to provided client",
    "574":"Error processing payment","575":"This card was declined because it was reported lost",
    "576":"This card was declined because it was reported stolen",
    "577":"This card has expired",
    "578":"This card's security code is incorrect",
    "579":"An error occurred while processing this card. Try again in a little bit.",
    "580":"This charge is considered fraudulent.",
    "581":"Appointment Recipe should have medications",
    "582":"Any recipe medication should have medication",
    "583":"It's necessary to indicate the reason of why the call couldn't carry out.",
    "584":"Appointment reminder not found",
    "585":"There should be defined, at least, one product name or substance name",
    "586":"There shouldn't be a diagnosis if the appointment couldn't carried out.",
    "587":"There shouldn't be a recipe if the appointment couldn't carried out.",
    "588":"Not valid payment intent",
    "589":"Payment without pending status",
    "590":"Doctor availability reserved by another user ",
    "591":"Doctor availability no longer reserved",
    "592":"There must be one diagnosis",
    "593":"Google tokens expired, please, refresh them so we can begin your synchronize process.",
    "594":"Outlook tokens expired, please, refresh them so we can begin your synchronize process.",
    "595":"Pharmacy not found",
    "596":"Doctor already has a pharmacy",
    "597":"Url not found",
    "598":"Warehouse not found",
    "599":"Zip code not found",
    "600":"Suburb not found",
    "601":"Municipality not found",
    "602":"State not found",
    "603":"Country not found",
    "604":"Zip Code not covered",
    "605":"Warehouse Coverage not found",
    "606":"Inventory not found",
    "607":"Product not found",
    "608":"Not available",
    "609":"Zip code not found",
    "610":"Visited product not found",
    "611":"Brand not found",
    "612":"Medication product not found",
    "613":"Frequently purchased with products not found",
    "614":"Frequent product not found",
    "615":"Also seen product not found",
    "616":"Related product not found",
    "617":"Multiple sale history not found",
    "618":"Category not found",
    "619":"Url already in use.",
    "620":"Discount not found",
    "621":"Product price not found",
    "622":"Currency already added for Product",
    "623":"Product image not found",
    "624":"Document not found",
    "625":"Segment not found",
    "626":"Sub Category not found",
    "627":"Product is already in Segment",
    "628":"Tag not found",
    "629":"Product is already in Tag",
    "630":"Product equals Frequent product",
    "631":"Combination exists",
    "632":"Product equals Also seen product",
    "633":"Product equals Related product",
    "634":"Product category not found",
    "635":"Product is already in Category",
    "636":"Product tag not found",
    "637":"Product is already in Tag",
    "638":"Product segment not found",
    "639":"Product is already in Segment",
    "640":"Product sub category not found",
    "641":"Product is already in Sub Category",
    "642":"Favorite product not found",
    "643":"Favorite product does not belong to the provided user",
    "644":"Product is already a favorite",
    "645":"Store banner not found",
    "646":"Telemedicine banner not found",
    "647":"Most sold product not found",
    "648":"Outstanding product not found",
    "649":"Sale product not found",
    "650":"New product not found",
    "651":"Relevant brand not found",
    "652":"Relevant bundle not found",
    "653":"Bundle product not found",
    "654":"Bundle not found",
    "655":"Bundle image not found",
    "656":"Product is already a Most Sold Product",
    "657":"Product is already a Outstanding Product",
    "658":"Product is already a Sale Product",
    "659":"Product is already a New Product",
    "660":"Brand is already a Relevant Brand",
    "661":"Bundle is already a Relevant Bundle",
    "662":"Name already taken",
    "663":"Bundle is already a bundle",
    "664":"Segment already in subcategory",
    "665":"Shipping type id not found",
    "666":"Shipping cost id not found",
    "667":"Shipping cost already exists",
    "668":"Cart not found",
    "669":"Code Discount Type not found",
    "670":"Code is already in use",
    "671":"Discount Code not found\r\n",
    "672":"Cart not found",
    "673":"Cart does not belong to selected user",
    "674":"Tax type not found",
    "675":"Cart already has same Tax Type",
    "676":"User already has un ordered cart",
    "677":"User must be null to perform this action.",
    "678":"Cart already ordered",
    "679":"Line item not found",
    "680":"Tax line not found",
    "681":"Line item does not belong to provided cart",
    "682":"Line item not found",
    "683":"Tax line does not belong to provided cart",
    "684":"Sale Discount Type not found",
    "685":"Cart already has same Product",
    "686":"Line Item belongs to an order",
    "687":"Line item does not belong to selected user",
    "688":"Line Item already has same Tax Type",
    "689":"Cart already has that kind of currency",
    "690":"This discount code cannot be used at this currency",
    "691":"Cart already has an owner",
    "692":"Order not found",
    "693":"Order does not belong to selected user",
    "694":"Zip Code not covered",
    "695":"Store payment not found",
    "696":"Order status not found",
    "697":"Shipping address not found",
    "698":"Cart is already related to an Order",
    "699":"Store payment not found",
    "700":"Store payment does not belong to provided user",
    "701":"Order has already been payed",
    "702":"Order hasn't been payed",
    "703":"Order has already been shipped",
    "704":"Store order not found",
    "705":"Provided payment doesnt belong to this order",
    "706":"Order has already a valid payment",
    "707":"No warehouse found fot this address","708":"Not valid external approved reference","709":"Not approved payment","710":"No items at selected cart.","711":"Order needs to have a warehouse id",
    "712":"User doesn't have a main address",
    "713":"User already have a main address",
    "714":"User already have a birth address",
    "715":"User doesn't have a birth address",
    "716":"Appointment Cancellation motive id does not exist",
    "717":"Discount code has exceded it's uses",
    "718":"Cart needs a certain product so it may has this discount",
    "719":"Cart doesn't apply because it's payment is lower than minimun purchase",
    "720":"This is a expired discount code",
    "721":"User of this cart does not match the one necessary to obtain this discount",
    "722":"User has not a birth address",
    "723":"User has already a birth address",
    "724":"User already has main address",
    "725":"User has not main address",
    "726":"Additional not found",
    "727":"Membership payment not found",
    "728":"Membership Payment does not belong to selected user",
    "729":"Additional does not belong",
    "730":"Not enough inventory of a product",
    "731":"Provided user is not part of an additional account",
    "732":"Discount code is no longer enabled",
    "733":"Currency does not correspond",
    "734":"Family group not found",
    "735":"User already belongs to a Family Group",
    "736":"Only the owner of the family group may edit it",
    "737":"Family invite not found",
    "738":"Family member not found",
    "739":"User does not belong to provided family group",
    "740":"Family member not found",
    "741":"Family invitation not found",
    "742":"Invitation does not belong to provided family group",
    "743":"Family group member is not allowed to invite someone into the group",
    "744":"Inviter not found",
    "745":"Family invitation does not belong to provided user",
    "746":"Family group invitation already answered",
    "747":"Membership not found",
    "748":"Membership does not belong to selected user",
    "749":"Membership type not found",
    "750":"Membership status not found",
    "751":"User already has a membership",
    "752":"Payment doesn't belong to selected user",
    "753":"Membership needs to be suspended",
    "754":"Membership already suspended",
    "755":"This membership hasn't been suspended",
    "756":"Seems that there's a trouble with your payment method, please, try again in a few minutes",
    "757":"Additional id or family group member id are required to continue",
    "758":"Related user not found",
    "759":"Provided users are not related",
    "760":"Membership owner is the only one who can add users to this membership",
    "761":"This membership cannot accept more members",
    "762":"User already belongs to a Membership",
    "763":"Membership member not found",
    "764":"User is not a member of selected membership",
    "765":"Membership doesn't cover this kind of speciality",
    "766":"User needs permission to edit others profile",
    "767":"Additional of not found",
    "768":"Duplicated availability",
    "769":"Blood type not found",
    "770":"User needs a mobile to confirm",
    "771":"Appontment type not found",
    "772":"Doctor does not match specialty",
    "773":"Multiple sale promotion has changed",
    "774":"Due to other purache; your included free products has already been redeemed",
    "775":"One product ot the cart had a sale that currently has expired",
    "776":"One cart product has a sale that currently is unavailable",
    "777":"Payment hasn't been payed yet",
    "778":"Appointment discount code not found",
    "779":"Payment concept does not correspond",
    "780":"Appointment discount code not found",
    "781":"Appointment Discount Code is being used",
    "782":"Discount Code is being used",
    "783":"Payment concept does not correspond",
    "784":"User of this payment does not match the one necessary to obtain this discount",
    "785":"To apply this discount there is needed a certain payment concept",
    "786":"Billing Preference not found",
    "787":"Appointment Billing Profile not found",
    "788":"Pharmacy Billing Profile not found",
    "789":"Membership Billing Profile not found",
    "790":"Billing profile not found",
    "791":"Billing profile does not belong",
    "792":"Invalid tax id",
    "793":"Billing profile not found",
    "794":"Payment Invoice not found",
    "795":"Payment Invoice does not belong",
    "796":"Store payment invoice not found",
    "797":"Store payment invoice does not belong",
    "798":"Membership Payment Invoice does not belong",
    "799":"Membership Payment Invoice not found",
    "800":"Code or Google Access Token are missing",
    "801":"There's already an invoice created for the provided payment",
    "802":"Membership isn't available",
    "803":"Heart Rate Record not found",
    "804":"Heart Rate Record does not belong to selected user",
    "805":"Device record not found",
    "806":"Manual record not found",
    "807":"Should have at least Device or Manual Record",
    "808":"Breathing frecuency record not found",
    "809":"Breathing frecuency record does not belong to selected user",
    "810":"Blood glucose record not found",
    "811":"Blood glucose record does not belong to selected user",
    "812":"Temperature Record not found",
    "813":"Temperature record does not belong to selected user",
    "814":"O2 saturation record not found",
    "815":"O2 saturation record does not belong to selected user",
    "816":"Systolic pressure record not found",
    "817":"Systolic pressure record does not belong to selected user",
    "818":"Weight Record not found",
    "819":"Weight Record does not belong to selected user",
    "820":"Diastolic pressure record not found",
    "821":"Diastolic pressure record does not belong to selected user",
    "822":"Height Record not found",
    "823":"Height Record does not belong to selected user",
    "824":"Imc Record not found",
    "825":"Imc Record does not belong to selected user",
    "826":"Head circunference record not found",
    "827":"Head circunference Record does not belong to selected user",
    "828":"Waist circunference record not found",
    "829":"Waist circunference Record does not belong to selected user",
    "830":"Hip perimeter record not found",
    "831":"Hip perimeter Record does not belong to selected user",
    "832":"Body fat record not found",
    "833":"Body fat Record does not belong to selected user",
    "834":"Muscle mass record not found",
    "835":"Muscle mass record does not belong to selected user",
    "836":"Bassal metabolism record not found",
    "837":"Bassal metabolism record does not belong to selected user",
    "838":"Visceral fat record not found",
    "839":"Visceral fat Record does not belong to selected user",
    "840":"Waist hip index record not found",
    "841":"Waist hip index record does not belong to selected user",
    "842":"User allergy not found",
    "843":"Allergy does not belong to selected user",
    "844":"Allergy already exists",
    "845":"Infant vaccination record already exists",
    "846":"Infant vaccination record doesn't exists",
    "847":"Adult vaccination record already exists",
    "848":"Adult vaccination record doesn't exists",
    "849":"Physical exploration record already exists",
    "850":"Physical exploration record doesn't exists",
    "851":"Apparatus exploration record already exists",
    "852":"Apparatus exploration record doesn't exists",
    "853":"Hospitalization not found",
    "854":"Hospitalization does not belong to the provided user",
    "855":"Diagnosis not found",
    "856":"Hospitalization type not found",
    "857":"Proceadure not found",
    "858":"Hospitalization document not found",
    "859":"Hospitalization document does not belong to selected user",
    "860":"Hereditary family history already exists",
    "861":"Hereditary family history doesn't exist",
    "862":"Non pathological history already exists",
    "863":"Non pathological history doesn't exist",
    "864":"Manual appointment not found",
    "865":"Manual appointment does not belong to selected user",
    "866":"Manual diagnosis not found",
    "867":"Manual diagnosis does not belong to selected user",
    "868":"Other infant vaccination not found",
    "869":"Other infant vaccination does not belong to selected user",
    "870":"Other adult vaccination not found",
    "871":"Other adult vaccination does not belong to selected user",
    "872":"Transfusion not found",
    "873":"Transfusion does not belong to selected user",
    "874":"Transfusion document not found",
    "875":"Transfusion document does not belong to the provided user",
    "876":"User study not found",
    "877":"User study does not belong to the provided user",
    "878":"Laboratory study not found",
    "879":"Study document not found",
    "880":"Study document does not belong to the provided user",
    "881":"User disability not found",
    "882":"User disability does not belong to the provided user",
    "883":"Disability not found",
    "884":"Disability document not found",
    "885":"Disability document does not belong to the provided user",
    "886":"Study request not found",
    "887":"Study request does not belong to the provided user",
    "888":"Contraceptive not found",
    "889":"Contraceptive does not belong to the provided user",
    "890":"Device Record not found",
    "891":"Device Record does not belong to selected user",
    "892":"Device not found",
    "893":"Manual Record not found",
    "894":"Manual Record does not belong to selected user",
    "895":"Pathological personal history already exists",
    "896":"Pathological personal history doesn't exist",
    "897":"Manual recipe not found",
    "898":"Manual recipe does not belong",
    "899":"Dosis Unit nof found",
    "900":"Alt Dosis Unit nof found",
    "901":"Frequency Unit not found",
    "902":"Alt Frequency Unit not found",
    "903":"Duration Unit not found",
    "904":"Alt Duration Unit not found",
    "905":"Manual recipe medication not found",
    "906":"Civil status not found",
    "907":"Scolarity not found",
    "908":"User application not found",
    "909":"User application does not belong",
    "910":"Application not found",
    "911":"List record not found",
    "912":"List record does not belong to selected user",
    "913":"List product not found",
    "914":"Product is already in list",
    "915":"Route not found",
    "916":"Route does not belong to selected user",
    "917":"Assigner not found",
    "918":"Route status not found",
    "919":"Delibery man not found",
    "920":"Route needs a pending status before been  assigned",
    "921":"Route needs to be assigned before it could be set to a preparing state",
    "922":"Route needs to be set to a ready state before being set to in transit",
    "923":"Route needs to be set to an in transit state before being set to completed",
    "924":"In order to finish an order, the order needs to have route orders",
    "925":"Route order should have in transit status",
    "926":"Route order not found",
    "927":"Route order status not found",
    "928":"Route Tracking not found",
    "929":"Warehouse user not found ",
    "930":"Route order not found ",
    "931":"Route Order Status not found",
    "932":"Order should have a pending or issue status",
    "933":"Route order should have pending status",
    "934":"Order should have a pending status",
    "935":"Route order should have in transit status",
    "936":"Order should have a shipped status",
    "937":"Store payment type id is required",
    "938":"List should have list products in order to add them to the cart",
    "939":"Log not found",
    "940":"Log action not found",
    "941":"Log model not found ",
    "942":"Product already has an image",
    "943":"Match not found ",
    "944":"Alternative not found ",
    "945":"Availability not found ",
    "946":"Slot already exists",
    "947":"Bundle image does not belong to selected bundle",
    "948":"Route needs to be set to a preparing state before being set to in ready",
    "949":"Code already used",
    "950":"Cancellation motive not found",
    "951":"Issue not found",
    "952":"Estimated delivery at hasn't been defined yet",
    "953":"Route order should have at destination status",
    "954":"Order signature should be defined",
    "955":"Order needs a recipe in order to be completed",
    "956":"Address must be shipping type",
    "957":"Address is not covered for delivery",
    "958":"Schedule time must be an exact half hour",
    "959":"Schedule time must be 2 hours away from current time",
    "960":"Selected Warehouse does not have pickup option available",
    "961":"Pickup time must be an exact half hour",
    "962":"Pickup time must be 2 hours away from current time",
    "963":"Order cannot be deleted due to it's status",
    "964":"Image order not found",
    "965":"User has exceeded maximum number of code uses",
    "966":"Route Order Issue not found",
    "967":"Suspension motive not found",
    "968":"Promotional banner not found",
    "969":"Blog post not found",
    "970":"Blog category found",
    "971":"Post type not found",
    "972":"Post product not found",
    "973":"Post product does not belong to selected blog post",
    "974":"Post tag does not belong to selected blog post",
    "975":"Post tag not found",
    "976":"Tag hasn't been added to blog post",
    "977":"Url not found",
    "978":"Url already used",
    "979":"Bank terminal not found",
    "980":"Bank terminal model not found",
    "981":"Bank terminal use not found",
    "982":"Sale price does not correspond",
    "983":"Sale price should be defined",
    "984":"Sale discount should be defined"
}