import { useState, useEffect } from "react"
import { private_server, public_server } from "../../config"
import { isFormValid, onGetErrorMessage, onGetFormData, onSetErrorsToForm, updateAuthorizationHeader } from "../../shared/utility"



const useForgottenPasswordModal = ({open, onClose}) => {

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [isValid, setIsValid] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [isEmailSent, setIsEmailSent] = useState(false)

    useEffect(() => {
        if(!open){
            setIsEmailSent(false)
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false)
        }
    }, [open])
    
    const actions = {
        onChange: (data) => {
            let temp = {...form}
            const id = data.config.id
            temp[id] = {...data}
            setForm(temp)
            setIsValid(isFormValid(temp))
        },
        onSubmit: async() => {
            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }

            //Extract data
            let data2send = onGetFormData(form)
            setLoading(true)
            try {
                updateAuthorizationHeader(public_server)
                const req = await public_server.post(`/user/passwordchange/request`, data2send)
                console.log(req)
                setLoading(false)
                setIsEmailSent(true)
                //await actions.onRefreshData()
                //actions.onUpdateModal('edit_user', false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
            setLoading(false)
            
        },
    }

    return {loading, actions, form, isEmailSent, error}
}

export default useForgottenPasswordModal

const formData = {
    username:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'username',
          type:'text',
          fullWidth: true,
          label:'Usuario',
          helperText:'Usuario no válido'
        },
        rules:{
          //type:'email',
           type:'distance',
           min:1
        }
    },
}