import React, { useState } from 'react';
import { Avatar, Card, Grid,  Icon,  makeStyles, Popover, Typography } from '@material-ui/core';
import { CONFIG_SETTINGS, DASHBOARD_SETTINGS, LAYOUT_SETTINGS, nodata } from '../../config';
import { blueGrey, grey } from '@material-ui/core/colors';
import SVGIcon from '../Structure/DisplayData/SVGIcon';
import cx from 'classnames'
import { theme } from '../../theme';
import nouser from '../../assets/nouser.png'
import { isValidDocument, onGetFullname } from '../../shared/utility';

const UserButton = ({mono, user, onItemSelected, isauth, content, ismobile}) => {

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)

    const onInnerItemSelected = (url) => {
        setAnchorEl(null)
        onItemSelected(url)
    }
    
    const onInnerClose = () => {
        setAnchorEl(null)
    }
    

    let userContent = null
    let isimagevalid = isValidDocument(user ? user.image : null)
    let fullname = user ? onGetFullname(user.first_name, user.last_name) : ''


    if(!user || !isimagevalid){
        userContent = (
                <Avatar src={nouser} alt='' className={classes.avatar}/>
            
        )
    }else{
        const imgSrc = user.image ? `${CONFIG_SETTINGS.S3_SERVER_URL}${user.image}` : null
        userContent =  <Avatar src={imgSrc} alt='' className={classes.avatar} />
    }

    const menu_options = content.user

    return ( 
        <div>
            <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onInnerClose} classes={{paper:classes.paper}}
                anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
                transformOrigin={{vertical: 'top',horizontal: 'left'}} >
                <div className={classes.popper_container}>
                    <Grid container>
                        {menu_options.map(item => {
                            return(
                                <Grid item xs={12} key={item.id.toString()}>
                                    <OptionComponent {...item} onItemSelected={onInnerItemSelected}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            </Popover>
            <div className={cx({
                [classes.card]:true,
                [classes.card_mono]:mono
            })} onClick={(e) => setAnchorEl(e.currentTarget)}>
                <Grid container alignItems='center' >
                    <Grid item>
                        {userContent}
                    </Grid>
                    {ismobile ? null : <Grid item>
                        <div className={classes.info}>
                            <Typography className={classes.name}>{fullname}</Typography>
                            <Typography color='primary' className={classes.user_type}>{user ? user.user_type : nodata}</Typography>
                        </div>
                    </Grid>}
                    <Grid item>
                        <div className={classes.icon_container} >
                            <Icon className={classes.icon} color='primary'>keyboard_arrow_down</Icon>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    card:{
        borderRadius:32,
        //padding:'5px 12px',
        paddingRight:12,
        cursor:'pointer',
        boxShadow:'0px 3.18949px 7.97373px rgba(0, 0, 0, 0.15)',
        //background:'white',
        //border:`2px solid ${grey[300]}`,
        '&:hover':{
            background: grey[100]
        }
    },
    card_mono:{
        background:'white',
        boxShadow:LAYOUT_SETTINGS.BOXSHADOW,
        border:'none'
    },
    info:{
        padding:'0px 12px'
    },
    avatar:{
        width:56,
        height:56
    },
    container:{
        marginTop:8,
        borderRadius:16,
        
    },
    icon:{  
        border:`0.1px solid ${theme.palette.primary.main}`,
        borderRadius:'50%',
    },
    icon_container:{
        [theme.breakpoints.down('sm')]:{
            marginLeft:12
        }
    },
  
    paper:{
        marginTop:4,
        borderRadius:12,
        width:250,
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW
    },
    popper_container:{
        padding:'12px 0px'
    },
    name:{
        fontSize:14,
        fontWeight:600, 
    },
    user_type:{
        fontSize:12,
        fontWeight:500, 
        //color:'#A2A2A2'
    }
}))
 
export default UserButton;

const useStyles4Option = makeStyles(theme=>({
    root:{
        position:'relative',
        color:'#737373',
        paddingLeft:theme.spacing(3),
        paddingRight:theme.spacing(3),
        fontFamily:theme.typography.fontFamily,
        transition:theme.transitions.create(['background'],{
            duration: theme.transitions.duration.standard,
            easing:theme.transitions.easing.easeIn
        }),
        '&:hover':{
            cursor:'pointer',
            //fontWeight:700,
            color:theme.palette.primary.main,
            transition:theme.transitions.create(['background','font-weight'],{
                duration: theme.transitions.duration.standard,
                easing:theme.transitions.easing.easeOut
            }),
        }
    },
    label_container:{
        padding:'12px 0px',
        
    },
    border:{
        borderTop:`1px solid ${grey[200]}`
    },
    icon:{color:theme.palette.primary.main}
}))

const OptionComponent = props => {

    const {label, onItemSelected, url, top_line, icon} = props
    const classes = useStyles4Option()

    return(
        <div className={cx({
            [classes.root]:true,

        })} onClick={() => onItemSelected(url)}>
            <div className={cx({
            [classes.label_container]:true,
            [classes.border]:top_line,
        })}>
                
                <Grid container spacing={1} alignItems='center'>
                    <Grid item><Icon >{icon}</Icon></Grid>
                    <Grid item>{label}</Grid>
                </Grid>
            </div>
        </div>
    )
}