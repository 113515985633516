import { createTheme  } from "@material-ui/core/styles";
import { blueGrey, lightBlue } from "@material-ui/core/colors";

export const theme = createTheme ({
    palette: {
      primary: { main: '#843BA8'}, //#1A237E //0847a7
      secondary: { main:'#D95D71', light:'#FCECED' /*lightBlue[700]*/ },  //#2196f3 //4466f2
      tertiary:{main:'#96AE76'},
      layout:{background:'#F1F1F2'},
      text:{
        primary:blueGrey[900],
        secondary:blueGrey[500],
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily:[
        'Gotham',
        'Montserrat',
        'Work Sans',
      ].join(','),
    },
    overrides:{
      MuiTypography:{
        h1:{
          fontSize:'4.5rem',
          fontWeight:500
        },
        h2:{
          fontSize:'3.75rem',
        },
        h3:{
          fontSize:'3rem',
          fontWeight:700,
        },
        h4:{
          fontWeight:700,
          fontSize:'2.5rem',
        },
        h5:{
            fontSize:'1.875rem',
            fontWeight:600
        },
        h6:{
          fontSize:'1.250rem',
          fontWeight:600,
        },
        subtitle1:{
          fontSize:'1rem',
          fontWeight:700,
        },
        subtitle2:{
          fontSize:'0.875rem',
          fontWeight:700,
        },
        body1:{
            fontSize:'1rem',
            fontWeight:500,
        },
        body2:{
            fontSize:'0.875rem',
            fontWeight:500,
        },
        caption:{
          fontSize:'0.750rem',
          fontWeight:500,
        },
        button:{
          fontSize:'1.125rem'
        },
      }
    }
});