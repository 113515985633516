
export const translations = {
    title:'Resumen',
    users_totals:'Usuarios totales',
    users:'Usuarios activos',
    admins:'Administradores',
    employers : 'Empleados',
    influencers : 'Influencers',
    managers : 'Managers',
    brands : 'Marcas',
    agencys : 'Agencias',
    deleted : 'Eliminados'
}

export default translations