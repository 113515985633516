import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../shared/utility'
import InputForm from '../../../components/Forms/InputForm'
import SimpleModal from '../../../components/Modals/SimpleModal'
import RoundedButton from '../../../components/Actions/RoundedButton'
import { private_server } from '../../../config'
import { modalStyles } from '../../../styles/modalStyles'
import AvatarPicture from '../../../components/Structure/DisplayData/AvatarPicture'


const EditCategoryModal = props => {

    const { open, onClose, onCompleted, origin, image, onUpdatePicture } = props

    const classes = modalStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }else{
            let _form  = onInitForm(form, origin)
            setForm(_form)
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            console.log(errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        

        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            console.log(data2send)
            await private_server.patch(`/category/${origin.id_category}`, data2send)
            await onCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            const _error = onGetErrorMessage(error)
            setError(_error ? _error.message : null)
        }
        setLoading(false)

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='xs'>
            <div >
                <Typography variant='h6'  style={{ fontWeight: 600 }}>Editar categoría</Typography>
                <Typography variant='body1' color='textSecondary' >Por favor ingresa los datos solicitados</Typography>
                <div className={classes.form}>
                    <Grid container spacing={3} justifyContent='center'>
                        <Grid item>
                            <AvatarPicture size={125} src={image} 
                                can_edit onChangePicture={onUpdatePicture}/>
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.url} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.description} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <RoundedButton color='primary' fullWidth onClick={onSubmit} loading={loading}>
                    Guardar
                </RoundedButton>
                <Typography color='error' align='center'>{error}</Typography>
            </div>
        </SimpleModal>
    )
}

export default EditCategoryModal


const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
            label: 'Url',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    
}