import React from 'react'
import { Grid, Icon, makeStyles, Typography } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import RoundedButton from '../../../components/Actions/RoundedButton'

const useStyles = makeStyles(theme => ({
    icon:{
        color:green[600],
        fontSize:100,
        border:`3px solid ${green[600]}`,
        borderRadius:'50%'
    }
}))

const SuccessView = props => {

    const { onReturn } = props
    const classes = useStyles()

    return(
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography align='center' color='primary' variant='h4'>¡Contraseña generada!</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align='center' color='bluegrey' size='large'>Ya puedes iniciar sesión con tu nueva contraseña</Typography>
                    </Grid>
                    <Grid  item xs={12}>
                        <Grid container justify='center'>
                            <Grid item>
                                <Icon className={classes.icon}>done</Icon>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>            
            <Grid item xs={12}>
                <div>
                    <Grid container spacing={4}>               
                        <Grid item xs={12}>
                            <RoundedButton fullWidth color='primary' onClick={onReturn}>Iniciar sesión</RoundedButton>
                        </Grid>
                    </Grid>
                </div>
            </Grid>         
        </Grid>
    )
}

export default SuccessView