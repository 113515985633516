import React from 'react';
import { Grid, Icon, IconButton, Typography } from '@material-ui/core';
import RoundedButton from './RoundedButton';

const ActionModalBar = ({btnLabel, loading, btnColor, error, onSubmit, onDelete}) => {

    return ( 
        <Grid container>
            <Grid item xs={12}>
                {error ? <Typography variant='subtitle2' color='error' align='center'>{error}</Typography> : null}
            </Grid>
            <Grid item xs={12}>
                <Grid container justify='flex-end'>
                    {onDelete ? (
                        <Grid item><IconButton onClick={onDelete} ><Icon>delete</Icon></IconButton>  </Grid>
                    ) : null}
                    <Grid item xs></Grid>
                    <Grid item>
                        <RoundedButton loading={loading} color={btnColor} onClick={loading ? null : onSubmit} >{btnLabel}</RoundedButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
     );
}
 
export default ActionModalBar;