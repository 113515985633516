import React from 'react'
import { CircularProgress, Grid, Icon, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { onGetFullname,onGetDateFormat,getCountryText,calcAge } from '../../../../shared/utility'
import SearchBar from '../../../../components/Actions/SearchBar'
import ActionBar from '../../../../components/Actions/ActionBar'
import GenericDeleteButton from '../../../../components/Actions/GenericDeleteButton'
import empty from '../../../../assets/icons/empty.png'
import { catalogs } from '../../../../texts/esp/catalogs'
import GenericStatus from '../../../../components/Structure/DisplayData/GenericStatus'
import tableStyles from '../../../../styles/tableStyles'
import UserAvatarName from '../../../../components/Structure/DisplayData/UserAvatarName'
import moment from 'moment'
import GenericStatusEditable from '../../../../components/Structure/DisplayData/GenericStatusEditable'



const SimpleTable = props => {

    const classes = tableStyles()
    const {data, filter, content, total, tableFilter, onUpdateTableFilter, onDeleteUser, loading, onChangeSearch, onAddRegister, onChangeStatuses,countries} = props
    const header = content.header

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container spacing={2} alignItems='center' > 
                    
                    <Grid item xs/>
                    <Grid item><SearchBar value={filter.query} onChange={onChangeSearch}/></Grid>      
                    <Grid item><IconButton className={classes.add_button} onClick={onAddRegister}><Icon>add</Icon></IconButton></Grid>   
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <Typography variant='subtitle2' className={classes.header} >{item.label}</Typography>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item, index) => {
                                    const {id_user, email, image, first_name, last_name, enabled,birth_date, referal_code, confirmed_at, gender,num_of_visits,username,
                                        influencer_type_id, influencer_size_id, membership_type_id, membership_status_id, types,table_from,created_at,updated_at,zone,country_id,categories,manager_username } = item
                                    
                                    const fullname = onGetFullname(first_name, last_name)
                                    const confirmed_date = moment(confirmed_at)
                                    let confirmed = confirmed_date.isValid() ? 1 : 0
                                    let _zone = '-'
                                    if(zone){
                                        _zone = zone
                                    }
                                    let categories_text = '-'
                                    if(categories){
                                        categories_text = ''

                                        categories.map((category,index) => {
                                            categories_text+=' - '+category.category_name
                                        })
                                    }
                                    let types_text = '-'
                                    if(types){
                                        types_text = ''

                                        types.map((type,index) => {
                                            types_text+=' - '+type.influencer_type
                                        })
                                    }
                                    return(
                                        <TableRow key={index.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} ><Typography variant='body1'>{ total - table_from - index }</Typography></TableCell>
                                            <TableCell className={classes.cell} ><UserAvatarName name={fullname} image={image} nopicture={content.nouser}/></TableCell>
                                            <TableCell className={classes.cell} ><Typography variant='body1'>{ id_user }</Typography></TableCell>
                                            <TableCell className={classes.cell} ><Typography variant='body1'>{username}</Typography></TableCell>  
                                            <TableCell className={classes.cell} ><Typography variant='body1'>{email}</Typography></TableCell>
                                            <TableCell className={classes.cell} ><Typography variant='body1'>{onGetDateFormat(created_at)}</Typography></TableCell>   
                                            <TableCell className={classes.cell} ><Typography variant='body1'>{onGetDateFormat(confirmed_at)}</Typography></TableCell> 
                                            <TableCell className={classes.cell} ><GenericStatusEditable selected={membership_type_id} options={catalogs.membership_types_influencers} onChange={(val)=>onChangeStatuses('membership_type', val, id_user)} color/></TableCell>
                                            <TableCell className={classes.cell} ><Typography variant='body1'>{_zone} / {getCountryText(country_id,countries)}</Typography></TableCell>
                                            {/*<TableCell className={classes.cell} ><GenericStatusEditable selected={membership_status_id} options={catalogs.membership_statuses} onChange={(val)=>onChangeStatuses('membership_status', val, id_user)} color/></TableCell>*/}
                                            <TableCell className={classes.cell} ><Typography variant='body1'>{ gender}</Typography></TableCell>
                                            <TableCell className={classes.cell} ><Typography variant='body1'>{ calcAge(birth_date)}</Typography></TableCell>
                                            <TableCell className={classes.cell} ><Typography variant='body1'>{ num_of_visits}</Typography></TableCell>
                                            <TableCell className={classes.cell} ><Typography variant='body1'>{ manager_username}</Typography></TableCell>
                                            <TableCell className={classes.cell} ><Typography variant='body1'>{ types_text}</Typography></TableCell>
                                            <TableCell className={classes.cell} ><Typography variant='body1'>{ categories_text}</Typography></TableCell>
                                            <TableCell className={classes.cell} ><Typography variant='body1'><GenericDeleteButton onDoubleClick={() => onDeleteUser(id_user, `${fullname} (${username})`)}></GenericDeleteButton></Typography></TableCell>
                                            
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <div className={classes.empty_container}>
                                <img src={empty} alt='' className={classes.empty}/>
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography>No se encontraron registros</Typography>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable