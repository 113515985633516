import React from 'react';
import { connect } from 'react-redux';
import Page from '../../components/Structure/Layouts/Page';
import { actionTypes } from '../../store/actions';
import system_translations from '../../texts/system_translations';
import SimpleTable from './components/SimpleTable';
import AddCategoryModal from './modals/AddCategoryModal';
import DeleteCategoryModal from './modals/DeleteCategoryModal';
import EditCategoryModal from './modals/EditCategoryModal';
import ProfilePictureModal from './modals/ProfilePictureModal/ProfilePictureModal';
import useCategoriesView from './useCategoriesView';



const CategoriesView = ({user, history, language}) => {

    const content = system_translations[language].views.categories
    const {system, view_data, actions, modals } = useCategoriesView({user, history})

    return ( 
        <div style={{minHeight:'100vh'}}>
            <ProfilePictureModal open={modals.image} onClose={() => actions.onUpdateModalStatus('image', false)} master_actions={actions} 
                origin={view_data.selectedItem}  />
            <AddCategoryModal open={modals.add_register} onClose={() => actions.onUpdateModalStatus('add_register', false)} onCompleted={actions.onInitModule} />
            <EditCategoryModal open={modals.edit_register} onClose={() => actions.onUpdateModalStatus('edit_register', false)} onCompleted={actions.onInitModule} 
               origin={view_data.selectedItem} image={view_data.image} onUpdatePicture={() => actions.onUpdateModalStatus('image', true)} />
            <DeleteCategoryModal open={modals.delete_register} onClose={() => actions.onUpdateModalStatus('delete_register', false)} onCompleted={actions.onInitModule} 
               origin={view_data.selectedItem} />
            <Page title={content.title} onAdd={() => actions.onUpdateModalStatus('add_register', true)}>
                <SimpleTable 
                    content={content.table}
                    loading={system.loading || system.sending}
                    data={view_data.data} 
                    filter={view_data.filter}
                    tableFilter={view_data.tableFilter}
                    total={view_data.total} 
                    onSelectedItem={actions.onSelectItem}
                    onUpdateTableFilter={actions.onUpdateTableFilter}
                    onChangeFilter={actions.onChangeFilter} 
                    onChangeSearch={actions.onChangeSearch}
                    onDeleteItem={actions.onDeleteSelected}/>
            </Page>
        </div>  
     );
}

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(CategoriesView);