import principal from '../../assets/banners/signup.png'
import logo from '../../assets/logo-text.svg'
import logoWhite from '../../assets/logo-text-white.svg'
import moment from 'moment'

const translations = {
   
    forgotten_password:'¿Olvidaste tu contraseña?',
    banner:principal,
    button:'Acceder',
    form:{
        username:{placeholder:'Usuario', label:'Usuario', helperText:'Campo requerido'},
        password:{placeholder:'Contraseña', label:'Contraseña', helperText:'Campo requerido'},
    },
    /* topbar:{
        logo:logo,
        register:'Únete ahora',
        login:'Iniciar sesión'
    },
    bottombar:{
        logo:logoWhite,
        media:[
            {id:1, name:'facebook', icon:null},
            {id:2, name:'twitter', icon:null},
            {id:3, name:'instagram', icon:null},
        ],
        links:{
            first_column:[
                {id:1, label:'Agencias', link:'/'},
                {id:2, label:'Influencers', link:'/'},
                {id:3, label:'Prensa', link:'/'},
                {id:4, label:'Marcas', link:'/'},
            ],
            second_column:[
                {id:1, label:'Contacto', link:'/'},
                {id:2, label:'Acerca de', link:'/'},
                {id:3, label:'Servicios', link:'/'},
            ],
            third_column:[
                {id:1, label:'Términos de uso', link:'/'},
                {id:2, label:'Aviso de privacidad', link:'/'},
                {id:3, label:'Ayuda', link:'/'},
            ],
        },
        copyright: `Copyright @ ${moment().year()} InPager`
    }, */
    
    
}

export default translations