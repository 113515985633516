import React from 'react';
import { connect } from 'react-redux';
import Page from '../../components/Structure/Layouts/Page';
import { actionTypes } from '../../store/actions';
import system_translations from '../../texts/system_translations';
import SimpleTable from './components/SimpleTable';
import EditReviewModal from './modals/EditReviewModal';
import useReviews from './useReviews';




const Reviews = ({user, history, language}) => {

    const content = system_translations[language].views.reviews
    const {system, view_data, actions, modals } = useReviews({user, history})

    return ( 
        <div style={{minHeight:'100vh'}}>
            {/* <ProfilePictureModal open={modals.image} onClose={() => actions.onUpdateModalStatus('image', false)} master_actions={actions} 
                origin={view_data.selectedItem}  />
            <AddCategoryModal open={modals.add_register} onClose={() => actions.onUpdateModalStatus('add_register', false)} onCompleted={actions.onInitModule} />         
            <DeleteCategoryModal open={modals.delete_register} onClose={() => actions.onUpdateModalStatus('delete_register', false)} onCompleted={actions.onInitModule} 
               origin={view_data.selectedItem} /> */}
            <EditReviewModal open={modals.edit_register} onClose={() => actions.onUpdateModalStatus('edit_register', false)} onCompleted={actions.onInitModule} 
               origin={view_data.selectedItem} onChangeApproved={actions.onChangeApproved}  />
            <Page title={content.title} >
                <SimpleTable 
                    content={content.table}
                    loading={system.loading || system.sending}
                    data={view_data.data} 
                    filter={view_data.filter}
                    tableFilter={view_data.tableFilter}
                    total={view_data.total} 
                    onSelectedItem={actions.onSelectItem}
                    onUpdateTableFilter={actions.onUpdateTableFilter}
                    onChangeFilter={actions.onChangeFilter} 
                    onChangeSearch={actions.onChangeSearch}
                    onDeleteItem={actions.onDeleteSelected}
                    onChangeApproved={actions.onChangeApproved}/>
            </Page>
        </div>  
     );
}

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Reviews);