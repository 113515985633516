import React from 'react'
import { alpha, Grid, TextField, withStyles } from '@material-ui/core'
import { ruleValidation } from './customFunctions'
import { makeStyles } from '@material-ui/styles'
import cx from 'classnames'
import { grey } from '@material-ui/core/colors'

const CustomSelectForm = props => {

    const {data, onChange, startAdornment, endAdornment, onKeyUp, onKeyDown} = props
    const classes = useStyles()

    const onInnerChange = value => {
        const response = value;
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    } 

    const {config, value, isValid, isVisited, options} = data

    let _options = options ? options : []

    const isError = isVisited && !isValid
    let interConfig = {...config}
    if(!isError) interConfig.helperText = ''


    return(
        <div>
            <Grid container spacing={3}>
                {_options.map(item => {
                  return(
                    <Grid item xs={12} md>
                        <div onClick={() => onInnerChange(item.value)} 
                        className={cx({
                            [classes.button]:true,
                            [classes.button_selected]:item.value === value
                        })}>
                            {item.label}
                        </div>
                    </Grid>
                  )
                })}
            </Grid>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    button:{
        border:`1px solid ${grey[400]}`,
        borderRadius:4,
        padding:'22px 24px',
        textAlign:'center',
        color:grey[600],
        cursor:'pointer',
        transition:'all 0.1s linear',
        '&:hover':{
            background: alpha(theme.palette.primary.main, 0.05)
        }
    },
    button_selected:{
        transition:'all 0.4s linear',
        border:`1px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main,
        fontWeight:600
    }
}))

export default CustomSelectForm