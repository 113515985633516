import { useState, useEffect } from "react"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../shared/utility"
import { private_server } from "../../config"
import queryString from 'query-string'



const useCategoriesView = ({history}) => {
    
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [data, setData] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [image, setImage] = useState(null)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10,offset:0})
    const [filter, setFilter] = useState({query:''})
    

    const [modals, setModals] = useState({
        add_register: false,
        edit_register:false,
        delete_register:false,
        image:false
    })
 
    useEffect(()=>{window.scrollTo(0,0)},[])

    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter])

    const onGetParams = () => {
        const {limit, offset} = tableFilter
        const {query} = filter

        let andFiltersArgs = [
            
        ]
     

        const param2send = {limit:limit, offset:limit*offset, order_by:'id_category', order:1}  
        let params = `?${queryString.stringify(param2send)}`
        

        if(query){
            andFiltersArgs.push({"field":"name","operator":"LIKE", "value":`%${query}%`})
            let finalArgs = {"AND":andFiltersArgs}
            params = `?${queryString.stringify(param2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`
        }

        return params
    }
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {     
                const _params = onGetParams()
                updateAuthorizationHeader(private_server)
                const request = await private_server.get(`/category/all${_params}`)
                console.log(request)
                const temp = request.data.data
                setTotal(temp.count)
                setData(temp.categories)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
            setLoading(false)
        },
        onChangeSearch:(data) => {
            //console.log(data)
            let temp = {...filter}
            temp.query = data
            setFilter(temp)
        },
        onDeleteSelected:(data) => {
            console.log(data)
            setSelectedItem(data)
            actions.onUpdateModalStatus('delete_register', true)
        },
        onSelectItem: (data) =>{
            setSelectedItem(data)
            setImage(data.image)
            actions.onUpdateModalStatus('edit_register', true)
        },
        onUpdateImage: (temp) => setImage (temp),
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        onUpdateTableFilter: (data) => setTableFilter(data),
        onChangeFilter: (data) => setFilter(data)
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, filter, selectedItem, image}

    return {system, actions, view_data, modals}
}

export default useCategoriesView