import nouser from '../../assets/nouser.png'

export const translations = {
    title:'Comentarios',
    table:{
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Usuario'},
            {id:3, label:'Comentario'},
            {id:4, label:'Satisfacción general'},
            {id:5, label:'Aprobado'},
        ],
        nodata:'No existen comentarios registrados',
        nouser:nouser
    }
    
}

export default translations