import { createStore } from 'redux'
import { updateObject } from '../shared/utility'
import { actionTypes } from './actions'

const initialState = {
    user:null,
    countries:null,
    is_auth:false,
    token:null,
    language:'es',
    cart:null,
    ip_address:null,
    drawer_status:true,
    blur:false,
    /////////// Modals /////////////
    modals:{
        signin:false,
        singup:false,
        register:false,
        recover_password:false
    },

    // Error Handler
    error:null,
}

const reducer = (state = initialState, action) => {

    switch(action.type){
        case actionTypes.AUTH_UPDATE_USER:
            return updateObject(state, {user:action.user})
        case actionTypes.AUTH_UPDATE_STATUS:
            return updateObject(state, {is_auth:action.is_auth})
        case actionTypes.SYS_UPDATE_DRAWER_STATUS:
            return updateObject(state, {drawer_status:action.drawer_status})
        case actionTypes.SYS_UPDATE_TOKEN:
            return updateObject(state, {token:action.token})
        case actionTypes.UPDATE_COUNTRIES:
            return updateObject(state,{countries:action.countries})
        case actionTypes.SYS_UPDATE_SESSION_STATUS:
            return updateObject(state, {session_status:action.session_status})
        case actionTypes.SYS_UPDATE_SESSION_STATUS:
            return updateObject(state, {session_status:action.session_status})
        //////////////////////   MODALS   //////////////////////
        case actionTypes.SYS_UPDATE_MODAL:
            return updateObject(state, {modals:{...state.modals, ...action.modal}})
        //////////////////////   ERROR HANDLER   //////////////////////
        case actionTypes.SYS_UPDATE_ERROR:
            return updateObject(state, {error:action.error})
        default:
            return state;

    }
}

export const store = createStore(reducer)

