import React, { useState, useEffect } from 'react';
import PublicTopbar from '../../components/Structure/Navigation/PublicTopbar';
import Footer from '../../components/Structure/Navigation/Footer';
import system_translations from '../../texts/system_translations';
import { alpha, Button, Fade, Grid, makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { actionTypes } from '../../store/actions';
import useSignInLayout from './useSignUpLayout';
import InputForm from '../../components/Forms/InputForm';
import RegisterMethodSection from './views/RegisterMethodSection';
import UserTypeSection from './views/UserTypeSection';
import FormDots from './components/FormDots';
import { grey } from '@material-ui/core/colors';
import PersonalInformationSection from './views/PersonalInformationSection';
import ProfileSection from './views/ProfileSection';
import OverviewSection from './views/OverviewSection';

const SignInLayout = ({history, language}) => {

    const classes = useStyles()
    const content = system_translations[language].layouts.signup
    const {system, form, actions, view_data} = useSignInLayout({language, content, history})

    useEffect(() => {
        window.scrollTo(0,0)
    },[])


    const onChangePage = (url) => {
        history.push(url)
    }

    const {REGISTER_STEPS} = content

    let content_view = null

    if(view_data.step === REGISTER_STEPS.METHOD_SELECTION) content_view = (
        <Fade in >
            <RegisterMethodSection content={content} history={history} actions={actions}/>
        </Fade>
    )
    if(view_data.step === REGISTER_STEPS.USER_TYPE_SELECTION) content_view = (
        <Fade in >
            <UserTypeSection content={content} history={history} actions={actions} view_data={view_data}/>
        </Fade>
    )

    if(view_data.step === REGISTER_STEPS.PERSONAL_INFORMATION) content_view = (
        <Fade in >
            <PersonalInformationSection form={form} content={content} history={history} actions={actions} view_data={view_data}/>
        </Fade>
    )

    if(view_data.step === REGISTER_STEPS.CREATE_YOUR_PROFILE) content_view = (
        <Fade in >
            <ProfileSection form={form} content={content} history={history} actions={actions} view_data={view_data}/>
        </Fade>
    )

    if(view_data.step === REGISTER_STEPS.OVERVIEW) content_view = (
        <Fade in >
            <OverviewSection form={form} content={content} history={history} actions={actions} view_data={view_data}/>
        </Fade>
    )

    return ( 

        <div className={classes.root}>
            <PublicTopbar content={content.topbar} onChangePage={onChangePage}/>
                <div className={classes.container}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <div className={classes.left_container}>
                                {content_view}
                                {view_data.step > 1 && view_data.step < 10 ? (
                                    <div style={{marginTop:40}}>
                                        <FormDots step={view_data.step}/>
                                    </div>
                                ) : null}
                            </div>
                            
                        </Grid>
                    </Grid>
                </div>
            <Footer content={content.bottombar}/>
            <div className={classes.overlay}/>
            <div className={classes.imageContainer}>
                <img src={content.banner} className={classes.image}/>
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        position:'relative',
        fontFamily:theme.typography.fontFamily,
        position:'relative'
    },
    imageContainer:{
        width:'50%',
        position:'absolute',
        top:0, left:'50%',
        width:'50%',
        height:'100vh',
        background:'#EEE'
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'cover'
    },
    overlay:{
        width:'50%',
        position:'absolute',
        top:0, left:'50%',
        width:'50%',
        height:'100vh',
        zIndex:3,
        background: alpha('#000',0.25),
    },
    container:{
        width:1400,
        margin:'auto',
        height:'100vh',
        //background:'red',
        boxSizing:'border-box',
        [theme.breakpoints.only('lg')]:{
            width:1200,
        },
        [theme.breakpoints.down('md')]:{
            width:'100%',
            padding:'0px 18px'
            //padding:'12px 0px',
            //height:300
        },
    },
    left_container:{
        position:'relative',
        padding:'24px 60px',
        paddingLeft:24,
        paddingRight:120,
        //height:'100vh',
        //background:"#DDD"
    },
    content_data:{
        marginTop:140,
    },
    link:{
        color:theme.palette.primary.main,
        fontWeight:600,
        textDecoration:`underline ${theme.palette.primary.main}`,
        cursor:'pointer'

    },
    button:{
        fontSize:18,
        textTransform:'none',
        background:theme.palette.primary.main,
        color:'white',
        fontWeight:600,
        padding:12,
        borderRadius:40,
        '&:hover':{
            background:theme.palette.primary.main,
        },
        '&:disabled':{
            background:grey[300]
        }
    },
    social_button:{
        fontSize:18,
        textTransform:'none',
        border:`1px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main,
        fontWeight:600,
        padding:12,
        borderRadius:40
    },
    form:{
        
        margin:'60px auto',
        width:400
    }
}))

const mapStateToProps = (state) => {
    return {
        user:state.user,
        cart:state.cart,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(SignInLayout);