import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { isFormValid, onGetDateFormat, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../shared/utility'
import { useStyles } from './styles'
import { catalogs } from '../../../../../texts/esp/catalogs'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Modals/SimpleModal'
import RoundedButton from '../../../../../components/Actions/RoundedButton'
import InputSelectForm from '../../../../../components/Forms/InputSelectForm'

import { private_server } from '../../../../../config'


const EditUserModal = props => {

    const { open, onClose, origin, view_data, actions, onRequestUsers, history } = props

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }else{
            let _form = onInitForm(form, origin);
             
            if(origin){
                _form.enabled.value = origin.enabled
                //_form.status_id.value = origin.status_id === 1
                
                //_form.birth_date.value = onGetDateFormat(origin.birth_date, 'YYYY-MM-DD')
            }
            setForm(_form)
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        /* if(id === 'enabled'){
            console.log(temp[id].value)
        } */
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, origin)
        console.log(data2send)
        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            await private_server.patch(`/user/${origin.id_user}`, data2send)
            await actions.onRefreshData()
            actions.onUpdateModal('edit_user', false)
        } catch (error) {
            console.log(error)
            const _error = onGetErrorMessage(error)
            setError(_error ? _error.message : null)
            setLoading(false)
        }

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <Typography variant='h6'  style={{ fontWeight: 600 }}>Editar información de usuario</Typography>
                <Typography variant='body1' color='textSecondary' >Por favor ingresa los datos solicitados</Typography>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}  md={6}>
                            <InputForm data={form.first_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}  md={6}>
                            <InputForm data={form.last_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.username} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.email} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.birth_date} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.mobile} onChange={onChange} />
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                            <InputForm data={form.phone} onChange={onChange} />
                        </Grid> */}
                        <Grid item xs={12} md={6}>
                            <InputSelectForm data={form.gender_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelectForm data={form.enabled} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <RoundedButton color='primary' fullWidth onClick={onSubmit} loading={loading}>
                    Guardar
                </RoundedButton>
                <Typography color='error' align='center'>{error}</Typography>
            </div>
        </SimpleModal>
    )
}

export default EditUserModal


const formData = {
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    birth_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'birth_date',
            type: 'date',
            fullWidth: true,
            label: 'Cumpleaños',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'date',
            //min: 1, max: 256
        }
    },
    username: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'username',
            type: 'text',
            fullWidth: true,
            label: 'Usuario',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido'
        },
        rules: {
            type: 'email',
        }
    },
    gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.genders],
        config: {
            id: 'gender_id',
            type: 'select',
            fullWidth: true,
            label: 'Género',
            helperText: 'Debe elegir una opción',
        },
        rules: {
            type: 'select',
        }

    },
    mobile: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'number',
            fullWidth: true,
            label: 'Celular',
            helperText: 'Número de celular no válido',
        },
        rules: {
            type: 'phone',
        }
    },
    /* phone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'phone',
            type: 'number',
            fullWidth: true,
            label: 'Teléfono (secundario)',
            helperText: 'Número de teléfono no válido',
        },
        rules: {
            type: 'phone',
        }
    }, */
    enabled: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.user_statuses],
        config: {
            id: 'enabled',
            type: 'select',
            fullWidth: true,
            label: 'Estatus',
            helperText: 'Debe elegir una opción',
        },
        rules: {
            type: 'select',
        }

    },
    
}