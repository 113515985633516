import { makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, {useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import WebsiteRouter from '../../routes/website-router';
import { actionTypes } from '../../store/actions';
import Topbar from './components/Topbar';
import cx from 'classnames'
import Footer from './components/Footer';
import useWebsiteLayout from './useWebsiteLayout';
import ErrorModal from '../../components/Modals/ErrorModal';
import system_translations from '../../texts/system_translations';
import Sidebar from './components/Sidebar';
import { DASHBOARD_SETTINGS } from '../../config';
import { Redirect } from 'react-router';




const WebsiteLayout = ({history, onUpdateURL, blur, user, is_auth, error, language, 
    onUpdateUserData, onUpdateAuthStatus, onUpdateCart, onUpdateError, drawer_status,onUpdateCountries}) => {

    const classes = useStyles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

    const {loading, sending, actions } = useWebsiteLayout({onUpdateUserData, onUpdateAuthStatus, onUpdateCart,onUpdateCountries})
    
    const content = system_translations[language].general

    let redirectContent = null
    if(!is_auth && !loading) redirectContent = <Redirect to='/signin'/>

    //console.log(content)

    return ( 
        <div className={cx({
            [classes.root]:true,
            [classes.root_blur]:blur
        })}>
            {redirectContent}
            <ErrorModal open={Boolean(error)} message={error ? error.message : null}
            onClose={() => onUpdateError(null)}/>
            <main className={classes.main} >
                <Topbar user={user} ismobile={ismobile} content={content.topbar}/>    
                <Sidebar user={user} language={language} ismobile={ismobile}/>
                <div className={cx({
                    [classes.subroot]:true,
                    [classes.subroot_shift]: !drawer_status,
                })}>
                    <WebsiteRouter/>
                </div>
            </main>
            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        //background:'#F4F6FC',
        background:'#fcfcfc',
        minHeight:'100vh',
        overflowY:'auto',
        filter:'none'

    },
    root_blur:{
        filter:'blur(3px)'
    },
    main:{
        //marginLeft:DASHBOARD_SETTINGS.PROFILE_DRAWER.WIDTH,
        //padding:16,
        overflowY:'auto',

        
    },
    subroot:{
        marginTop:72,
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: DASHBOARD_SETTINGS.PROFILE_DRAWER.WIDTH,
        [theme.breakpoints.down('sm')]: {
            marginLeft:0
          },
    },
    subroot_shift:{
        marginLeft: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        
    },
}))

const mapStateToProps = state => {
    return {
        is_auth: state.is_auth,
        ip_address:state.ip_address,
        blur: state.blur,
        user: state.user,
        error: state.error,
        language:state.language,
        drawer_status:state.drawer_status,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateCountries: (countries) => dispatch({type:actionTypes.UPDATE_COUNTRIES,countries}),
        onUpdateAuthStatus: (is_auth) => dispatch({type:actionTypes.AUTH_UPDATE_STATUS, is_auth}),
        onUpdateError: (error) => dispatch({type:actionTypes.SYS_UPDATE_ERROR, error})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteLayout);

