import { Button, Dialog, Grid, Grow, Icon, makeStyles, Typography } from '@material-ui/core'
import { red } from '@material-ui/core/colors';
import React from 'react'

const Transition = React.forwardRef(function Transition(props, ref) {return <Grow ref={ref} {...props} timeout={500} />;});

const useStyles = makeStyles(theme => ({
    paper:{
        borderRadius:32, 
        padding:theme.spacing(5)
    },
    root:{
        paddingTop:32,
        paddingBottom:0
    },
    icon:{
        color:'white',
        background:red[700],
        borderRadius:'50%',
        fontSize:54,
        padding:8
    },
    message:{
        paddingTop:16,
        paddingBottom:16,
        maxWidth:300
    }
}))

const ErrorModal = (props) => {

    const classes = useStyles()
    const {open, message, onClose, history} = props

    return(
        <Dialog open={open} maxWidth='xs' fullWidth classes={{ paper: classes.paper }} className={classes.root}
        TransitionComponent={Transition}>
            <div className={classes.root}>
                <Grid container direction='column' justifyContent='center' alignItems='center' spacing={0}>
                    <Grid item>
                        <div className={classes.iconContainer}>
                            <Icon className={classes.icon}>close</Icon>
                        </div>
                    </Grid>
                    <Grid item>
                        <Typography variant='subtitle1' style={{paddingTop:8}}>Opps...</Typography>
                    </Grid>
                    {/* <Grid item>
                        <Typography variant='subtitle1'>hubo un error</Typography>
                    </Grid> */}
                    <Grid item>
                        <div className={classes.message}>
                            <Typography custom_color='bluegrey' align='center'>{message}</Typography>
                            <Typography custom_color='bluegrey' align='center'>Por favor vuelve a intentarlo</Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        <Button size='large' onClick={()=>history.push('/signin')}>Continuar</Button>
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    )
}

export default ErrorModal
