import React, { useState, useEffect } from 'react';
import { alpha, AppBar, Button, Grid, useScrollTrigger, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames'

const PublicTopbar = ({content, onChangePage}) => {

    const classes = useStyles()

    const trigger = useScrollTrigger({disableHysteresis:true, threshold:100})

    return ( 
        <AppBar className={cx({
            [classes.bar]:true,
            [classes.bar_shadow]:trigger
        })}>
            <div className={classes.root}>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item>
                        <a href='/'><img src={content.logo} className={classes.logo}/></a>
                    </Grid>
                    <Grid item xs/>
                    <Grid item>
                        <CustomButton mono={!trigger} noborder={true} onClick={() => onChangePage('/signup')}>
                            {content.register}
                        </CustomButton>
                    </Grid>
                    <Grid item>
                        <CustomButton mono={!trigger} onClick={() => onChangePage('/signin')}>
                            {content.login}
                        </CustomButton>
                    </Grid>
                </Grid>
            </div>
        </AppBar>
    );
}
 
const useStyles = makeStyles(theme => ({
    bar:{
        background:'transparent',
        boxShadow:'none'
    },
    bar_shadow:{
        boxShadow:'0px 4px 5px rgba(0, 0, 0, 0.13)',
        background:'white'
    },
    root:{
        width:1400,
        margin:'auto',
        boxSizing:'border-box',
        padding:'16px 24px',
        [theme.breakpoints.down('lg')]:{
            width:1200,
            //height:300
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
        }
    },
    logo:{
        width:100,
        
    }
}))

export default PublicTopbar;

const useCustomButtonStyles = makeStyles(theme => ({
    button:{
        background:'transparent',
        color:theme.palette.primary.main,
        textTransform:'none',
        fontFamily:theme.typography.fontFamily,
        fontSize:16,
        fontWeight:700,
        border:`1px solid ${theme.palette.primary.main}`,
        borderRadius:32,
        padding:'8px 24px'
    },
    button_mono:{
        color:'white',
        border:`1px solid white`,
    }
}))

const CustomButton = ({children, onClick, mono, noborder}) => {

    const classes = useCustomButtonStyles()

    return(
        <Button onClick={onClick} className={cx({
            [classes.button]:true,
            [classes.button_mono]:mono
        })} style={noborder ? {border:`1px solid ${alpha('#FFF',0.0001)}`} : undefined}>
            {children}
        </Button>
    )
}