import { amber, blue, cyan, green, indigo, orange, purple, red, teal, pink, lime, grey, deepPurple } from "@material-ui/core/colors";

export const catalogs = {
    intercesion_days:[],
    intercesion_hours :[],
    user_statuses:[
        {value:0, label:'Inactivo', color:red[700]},
        {value:1, label:'Activo', color:green[700]},     
    ],
    user_confirm_statuses:[
        {value:0, label:'Pendiente', color:amber[700]},
        {value:1, label:'Confirmado', color:'#843BA8'},     
    ],
    user_types:[
        {value:1, label:'Administrador', color:red[700]},
        {value:2, label:'Empleado', color:red[700]},
        {value:3, label:'Manager', color:red[700]},
        {value:4, label:'Marca', color:red[700]},
        {value:5, label:'Agencia', color:red[700]},
        {value:6, label:'Influencer', color:red[700]},
    ],
    orders_statuses:[
        {value:1, label:'En proceso', color:"#A149C0", number:0.42},
        {value:2, label:'Entregado', color:green[700], number:0.23},
        {value:3, label:'Vencido', color:red[700], number:0.3},
        {value:4, label:'Cancelado', color:grey[700], number:0.05},
    ],
    applicants:[
        {value:1, label:'UNOPS', color:green[700]},
        {value:2, label:'INSABI-OM', color:red[700]},
    ],
    orders_years:[
        {value:'2021', label:'2021', color:green[700]},
        {value:'2022', label:'2022', color:red[700]},
    ],
    orders_events:[
        {value:1, label:'Patentes', color:"#A149C0", },
        {value:2, label:'Fuente única', color:green[700], },
        {value:3, label:'Oncológicos', color:red[700], },
        {value:4, label:'Priorizados', color:grey[700], },
    ],
    orders_invoiced:[
        {value:1, label:'Si', color:green[700]},
        {value:0, label:'No', color:red[700]},
    ],
    orders_delivery_types:[
        {value:1, label:'Directo', color:"#A149C0", },
        {value:2, label:'Operador logistico', color:green[700], },
        {value:3, label:'Domiciliado', color:red[700], },
    ],
    genders:[
        {value:1, label:'Masculino'},
        {value:2, label:'Femenino'},
        {value:3, label:'Otro'}
    ],
    specialities:[
        {id:1, label:'Dermatología'},
        {id:2, label:'Medicina General'},
        {id:3, label:'Nutrición'},
        {id:4, label:'Psicología'},
    ],
    currency:[
        {value:1, label:'Dolar (USD)', code:'USD', text_id:301},
        {value:2, label:'Peso (MXN)', code:'MXN', text_id:302},
    ],
    influencer_sizes:[
        {value:1, label:'Nano', color:pink[500]},
        {value:2, label:'Micro', color:blue[500]},
        {value:3, label:'Mid-tier', color:teal[500]},
        {value:4, label:'Macro', color:orange[500]},
        {value:5, label:'Mega', color:amber[500]},
    ],
    influencer_types:[
        {value:1, label:'Socialite', color:lime[500]},
        {value:2, label:'Creador de contenido', color:amber[500]},
        {value:3, label:'Celebridad', color:cyan[500]}
    ],
    membership_types_influencers:[
        {value:8, label:'Básica - mensual', color:blue[500]},
        {value:9, label:'Básica - anual', color:indigo[500]},
        {value:10, label:'Premium - mensual', color:deepPurple[500]},
        {value:11, label:'Premium - anual', color:blue[500]},
        {value:12, label:'Profesional - mensual', color:indigo[500]},
        {value:13, label:'Profesional - anual', color:deepPurple[500]}
    ],
    membership_types_managers:[
        {value:1, label:'Mensual', color:blue[500]},
        {value:2, label:'Anual', color:indigo[500]},
    ],
    membership_statuses:[
        {value:1, label:'Inactiva', color:blue[500]},
        {value:2, label:'Activa', color:indigo[500]},
        {value:3, label:'Suspendida por el usuario', color:blue[500]},
        {value:4, label:'Suspendida por falta de pago', color:indigo[500]},
    ]
}