import React, {useState} from 'react'
import { makeStyles} from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import FormView from './components/FormView'
import SuccessView from './components/SuccessView'
import SimpleCard from '../../components/Structure/Cards/SimpleCard'
import useResetPasswordView from './useResetPasswordView'
import ErrorModal from './modals/ErrorModal'
//import ErrorModal from '../../modals/ErrorModal'



const ResetPasswordView = props => {

    const { history } = props

    const classes = useStyles()
    const {system, isPasswordRecover, form, actions} = useResetPasswordView({history})
    
    
    let contentView = (
        <FormView form={form} onChange={actions.onChange} loading={system.loading} error={system.error}
        onSubmit={actions.onSubmit} history={history}/>
    )

    if(isPasswordRecover){
        contentView = <SuccessView onReturn={()=>props.history.push('/signin')}/>
    }


    return(
        <div className={classes.extraroot}>
            <ErrorModal open={Boolean(system.error)} message={system.error} history={history} onClose={actions.onClearError}/>
            <div className={classes.root}>
                <SimpleCard>
                    <div className={classes.card}>
                        {contentView}
                    </div>
                </SimpleCard>
            </div>
        </div>
    )
}

export default ResetPasswordView

const useStyles = makeStyles(theme => ({
    root:{
        /* width:600,
        margin:'auto',
        //width:'100%',
        //backgroundColor:'gray',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        }, */
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)',
        zIndex:2
    },
    card:{
        padding:theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            padding:theme.spacing(3),
        },
    },
    link:{
        color:blueGrey[500],
        fontWeight:500,
        fontFamily:'Roboto',
        textAlign:'center',
        '&:hover':{cursor:'pointer', color:theme.palette.secondary.main,}
    },
    extraroot:{
        position:'relative',
        background:theme.palette.primary.main,
        width:'100%',
        minHeight:'100vh',
        //padding:13
    }
}))


const formData = {
    password:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'password',
          type:'password',
          fullWidth: true,
          label:'Contraseña',
          helperText:'Debe contener 6 a 20 caracteres, incluyendo al menos 1 dígito, 1 mayúscula y 1 minúscula'
        },
        rules:{
          type:'password',
        }
    },
    passwordConfirmation:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'passwordConfirmation',
          type:'password',
          fullWidth: true,
          label:'Confirmar contraseña',
          helperText:'La contraseña no coincide'
        },
        rules:{
          type:'equals',
        }
    },
}