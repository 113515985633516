import { makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors'

export const modalStyles = makeStyles(theme=>({
    root:{
        position:'relative'
    },
    paper:{
        borderRadius:32, 
        padding:theme.spacing(3)
    },
    buttonContainer:{
        position:'absolute',
        top:16,
        right:16
    },
    container:{
        padding:theme.spacing(4),
    },
    form:{
        paddingTop:theme.spacing(6),
        paddingBottom:theme.spacing(4),
    },
    linkContainer:{
        marginTop:16,
        marginBottom:16
    },
    link:{
        color:theme.palette.secondary.main,
        fontWeight:500,
        fontFamily:theme.typography.fontFamily,
        textAlign:'center',
        '&:hover':{cursor:'pointer', color:theme.palette.secondary.dark,}
    },
    successIcon:{
        fontSize:64,
        color:green[700],
        border:`2px solid ${green[700]}`,
        borderRadius:'50%',
        marginTop:32,
        marginBottom:32
    }
}))