import React, {useState, useEffect} from 'react'
import { Button, CircularProgress, Dialog,  Grid, Grow, Icon, IconButton, makeStyles, Typography } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import InputForm from '../../components/Forms/InputForm'
import { useStyles } from './styles'
import SimpleModal from '../../components/Modals/SimpleModal'
import useForgottenPasswordModal from './useForgottenPasswordModal'
import RoundedButton from '../../components/Actions/RoundedButton'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow  ref={ref} {...props} timeout={500}/>;
});


const ForgottenPasswordModal = props => {

    const {open, onClose} = props

    const classes = useStyles()
    const {loading, actions, form, isEmailSent, error} = useForgottenPasswordModal({open})

    return(
        <SimpleModal open={open} maxWidth='xs' TransitionComponent={Transition}>
            <div className={classes.buttonContainer}><IconButton onClick={() => onClose('forgottenpassword')}><Icon >close</Icon></IconButton></div>
            <div className={classes.container}>
                <Typography variant='h6' color='primary'>Recupera tu contraseña</Typography>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{color:blueGrey[700]}}>
                                {!isEmailSent ? (
                                    'Por favor ingresa tu nombre de usuario, enviaremos un mensaje a tu correo para que puedas cambiar la contraseña'
                                ) : (
                                    'El correo ha sido enviado de manera exitosa, ahí encontrarás los pasos a seguir para restaurar tu contraseña'
                                )}
                            </Typography>
                        </Grid>
                        {!isEmailSent ? (<Grid item xs={12}>
                            <InputForm data={form.username} onChange={actions.onChange}/>
                        </Grid>) : null}
                        {isEmailSent ? (
                            <Grid item xs={12}>
                                <Grow in timeout={650}>
                                    <Grid container justify='center'>
                                        <Grid item><Icon className={classes.checkIcon}>done</Icon></Grid>
                                    </Grid>
                                </Grow>
                            </Grid>
                        ) : null}          
                    </Grid>
                </div>
                <div style={{textAlign:'center', width:'100%'}}>
                    <RoundedButton 
                        onClick={ isEmailSent ? onClose : actions.onSubmit}
                        >
                        {isEmailSent ? 'Ok' : 'Enviar'}
                        {loading ? (<CircularProgress style={{width:24, height:24,marginLeft:8,color:'white'}}/>) : null}
                    </RoundedButton>
                    <div className={classes.linkContainer}>  
                        <Typography color='red' align='center'>{error}</Typography>
                    </div>
                </div>
            </div>
        </SimpleModal>
    )
}

export default ForgottenPasswordModal


