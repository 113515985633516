import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { isFormValid, onGetErrorMessage, onGetFormData, onGetFullname, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../shared/utility'
import { useStyles } from './styles'
import { catalogs } from '../../../../../texts/esp/catalogs'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Modals/SimpleModal'
import RoundedButton from '../../../../../components/Actions/RoundedButton'
import InputSelectForm from '../../../../../components/Forms/InputSelectForm'
import { private_server, USER_TYPES } from '../../../../../config'


const AddInfluencerModal = props => {

    const { open, onClose, view_data, actions, onRequestUsers, history, managers } = props

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
        if (!open) {
            
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }else{
            let _form = JSON.parse(JSON.stringify(formData))
            _form.manager_id.options = onGetManagerCatalog()
            setForm(_form)
        }
    }, [open])

    const onGetManagerCatalog = () => {
        let options = []
        if(managers){
            managers.forEach(item => {
                const fullname = onGetFullname(item.first_name, item.last_name)
                options.push({
                    value:item.id_user,
                    label:fullname,
                })
            })
        }
        return options
    }



    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'password') temp['passwordConfirmation'].value2 = temp[id].value
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        data2send.user_type_id = USER_TYPES.INFLUENCER

        if(data2send.manager_id){
            console.log('Serach for referal code')
            const _manager = managers.find(el => el.id_user == data2send.manager_id)
            if(_manager) {
                console.log(_manager.referal_code)
                if(_manager.referal_code) data2send.reference_code = _manager.referal_code
            }
            delete data2send.manager_id
        }

        console.log(data2send)


        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            await private_server.post('/user/invite', data2send)
            await actions.onUpdateUsers()
            actions.onUpdateModalStatus('add_register', false)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            
        }
        setLoading(false)

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <Typography variant='h6'  style={{ fontWeight: 600 }}>Agregar influencer</Typography>
                <Typography variant='body1' color='textSecondary' >Por favor ingresa los datos solicitados</Typography>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}  md={6}>
                            <InputForm data={form.first_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}  md={6}>
                            <InputForm data={form.last_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.email} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelectForm data={form.gender_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelectForm data={form.manager_id} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <RoundedButton color='primary' fullWidth onClick={onSubmit} loading={loading}>
                    Agregar
                </RoundedButton>
                <Typography color='error' align='center'>{error}</Typography>
            </div>
        </SimpleModal>
    )
}

export default AddInfluencerModal


const formData = {
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido'
        },
        rules: {
            type: 'email',
        }
    },
    gender_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.genders],
        config: {
            id: 'gender_id',
            type: 'select',
            fullWidth: true,
            label: 'Género',
            helperText: 'Debe elegir una opción',
        },
        rules: {
            type: 'select',
        }

    },
    manager_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'manager_id',
            type: 'select',
            fullWidth: true,
            label: 'Manager',
            helperText: 'Manager no válido'
        },
        rules: {
            type: 'select',
        }
    },
}