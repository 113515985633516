import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { isFormValid, onGetErrorMessage, onGetFormData, onGetFullname, onGetSelectedFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../shared/utility'
import InputForm from '../../../../components/Forms/InputForm'
import SimpleModal from '../../../../components/Modals/SimpleModal'
import RoundedButton from '../../../../components/Actions/RoundedButton'
import { private_server, USER_TYPES } from '../../../../config'
import { modalStyles } from '../../../../styles/modalStyles'
import moment from 'moment'

const EditMembershipDateModal = props => {

    const { open, onClose, actions, origin } = props
    console.log(origin)

    const classes = modalStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }else{
            let _form = onInitForm(form, origin);
            _form.membership_start_date.value =  moment(origin.membership_start_date).format('YYYY-MM-DD')
            setForm(_form)
        }
    }, [open])

 

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, origin)
        console.log(data2send)

        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            await private_server.patch(`/user/${origin.id_user}`, data2send)
            actions.onInitModule()
            actions.onUpdateModalStatus('edit_membership_date', false)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            
        }
        setLoading(false)

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='xs'>
            <div >
                <Typography variant='h6'  style={{ fontWeight: 600 }}>Editar fecha de inicio de la membresía</Typography>
                {/* <Typography variant='body1' color='textSecondary' >Por favor ingresa los datos solicitados</Typography> */}
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}  >
                            <InputForm data={form.membership_start_date} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <RoundedButton color='primary' fullWidth onClick={onSubmit} loading={loading}>
                    Guardar
                </RoundedButton>
                <Typography color='error' align='center'>{error}</Typography>
            </div>
        </SimpleModal>
    )
}

export default EditMembershipDateModal


const formData = {
    membership_start_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'membership_start_date',
            type: 'date',
            fullWidth: true,
            label: 'Fecha de inicio',
            helperText: 'Fecha inválida'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    
}