import React from 'react'
import { Grid, Icon, InputBase, makeStyles } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import useSearchBar from './useSearchBar'

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        padding:'8px 16px',
        borderRadius:32,
        width:400
        //minWidth:255
    },
    icon:{
        color:blueGrey[500],

    }
}))

const SearchBar = props => {

    const classes = useStyles()

    const { onUpdateTableFilter, tableFilter, onChange, search} = props
    const {inputRef, actions} = useSearchBar({ onUpdateTableFilter, tableFilter, onChange })


    return(
        <div className={classes.root}>
            <Grid container alignItems='center' spacing={1}>
                <Grid item>
                    <Icon color='primary' fontSize='default'>search</Icon>
                </Grid>
                <Grid item>
                    <InputBase value={search} placeholder='Buscar...' onChange={actions.onUpdateSearchValue} 
                        onKeyUp={actions.onStartCountDown} onKeyDown={actions.onStopCountDown} 
                        ref={inputRef}/>
                </Grid>
            </Grid>
        </div>
    )
}

export default SearchBar