import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const Footer = ({content}) => {

    const classes = useStyles()

    return ( 
        <div className={classes.root}>
            <div className={classes.container}>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <div className={classes.logoContainer}>
                            <img src={content.logo} />
                            <div className={classes.mediaContainer}>
                                {content.media.map(item => {
                                    return(
                                        <div className={classes.media_button} key={item.id.toString()}>
                                            
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <div className={classes.linksContainer}>
                            <Grid container justifyContent='space-between'>
                                <Grid item xs={12} md={4}>
                                    <Grid container spacing={4}>
                                        {content.links.first_column.map(item => {
                                            return(
                                                <Grid item xs={12}>
                                                    <a href={item.link} className={classes.link}>
                                                        {item.label}
                                                    </a>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Grid container spacing={4}>
                                        {content.links.second_column.map(item => {
                                            return(
                                                <Grid item xs={12}>
                                                    <a href={item.link} className={classes.link}>
                                                        {item.label}
                                                    </a>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Grid container spacing={4}>
                                        {content.links.third_column.map(item => {
                                            return(
                                                <Grid item xs={12}>
                                                    <a href={item.link} className={classes.link}>
                                                        {item.label}
                                                    </a>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.copyright}>
                    {content.copyright}
                </div>
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:'black',
        fontFamily:theme.typography.fontFamily,
    },
    container:{
        width:1400,
        margin:'auto',
        boxSizing:'border-box',
        color:'white',
        padding:'60px 24px',
        paddingBottom:20,
        [theme.breakpoints.down('lg')]:{
            width:1200,
            //height:300
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
        }
    },
    logoContainer:{
        borderRight:'1px solid #686868'
    },
    mediaContainer:{
        display:'flex',
        alignItems:'center',
        marginTop:16,
        height:'100%'
    },
    media_button:{
        width:54,
        paddingTop:54,
        background:'#868686',
        borderRadius:8,
        marginRight:16
    },
    link:{
        textDecoration:'none',
        
        color:'white',
        fontSize:'1.125rem'
    },
    linksContainer:{
        margin:'0px 100px'
    },
    copyright:{
        width:'100%',
        //border:'1px solid white',
        textAlign:'center',
        paddingTop:60,
        fontSize:'0.875rem'
    }
}))
 
export default Footer;