import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Page from '../../components/Structure/Layouts/Page';
import { actionTypes } from '../../store/actions';
import system_translations from '../../texts/system_translations';
import { Card,CardContent,List,ListItem,ListItemText, makeStyles } from '@material-ui/core'
import useResumeView from './useResumeView';

const ResumeView = ({user, history, language}) => {

    const content = system_translations[language].views.resume
    const {view_data,actions,system} = useResumeView({user})

    const getText = (key) => {        
        return content[key]
    }
    return ( 
        <div style={{minHeight:'100vh'}}>
            <Page title={content.title} onExport={() => actions.exportUsers()}>
                <Card >
                    <CardContent >
                        {view_data.metrics != false ?
                        <List >
                            <ListItem>
                                <strong>{getText('users_totals')} : {view_data.metrics.users + view_data.metrics.deleted}</strong>
                            </ListItem> 
                            {Object.keys(view_data.metrics).map((key,index) => {
                                return (
                                    <ListItem style = {key == 'deleted' ?  {color:'red',fontWeight:'bold'} 
                                        : key == 'users' ? {color:'green',fontWeight:'bold'} : {marginLeft:'24px'}}

                                        >
                                        {getText(key)} : {view_data.metrics[key]}
                                    </ListItem>
                                )
                            })}
                        </List>

                        : null}
                    </CardContent>
                </Card>

            </Page>
        </div>  
     );
}

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ResumeView);