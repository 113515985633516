import nouser from '../../assets/nouser.png'

export const translations = {
    title:'Usuarios',
    table:{
        header:[
            {id:0, label:'*'},
            {id:1, label:'Nombre'},
            {id:2, label:'ID'},
            {id:7, label:'Usuario'},
            {id:3, label:'Correo electrónico'},
            {id:4, label:'Teléfono'},
            {id:5, label:'Tipo'},
            {id:6, label:'Estatus'},
            {id:8, label:'Acciones'},
        ],
        nodata:'No existen usuarios registrados',
        nouser:nouser
    }
    
}

export default translations