import nouser from '../../assets/nouser.png'

export const translations = {
    title:'Agencias',
    table:{
        header:[
            {id:0, label:'*'},
            {id:1, label:'Nombre'},
            {id:2, label:'ID'},
            {id:3, label:'Usuario'},
            {id:4, label:'Correo electrónico'}, 
            {id:5, label:'Estatus'},
            {id:6, label:'Membresía'},
            {id:7, label:'Inicio de la membresía'},
            {id:8, label:'Estatus de la membresía'},
            //{id:9, label:'Cupón'},
            //{id:8, label:'Calificación'},
        ],
        nodata:'No existen agencias registradas',
        nouser:nouser
    }
    
}

export default translations